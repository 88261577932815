import React from "react";
import videojs from "video.js";
import styled from "styled-components";
import "./styles.scss";

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      // console.log("onPlayerReady", this);
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <PlayerWrapper data-vjs-player>
        <video
          id="my_video_1"
          className="video-js vjs-default-skin vjs-big-play-centered"
          controls
          ref={(node) => (this.videoNode = node)}
          poster={this.props.thumbnail}
          data-setup='{ "aspectRatio":"16:9", "playbackRates": [1, 1.5, 2] }'
        >
          <source src={this.props.src} type="video/mp4" />
        </video>
      </PlayerWrapper>
    );
  }
}

VideoPlayer.defaultProps = {
  src: "https://vjs.zencdn.net/v/oceans.mp4",
  thumbnail: "",
};
const PlayerWrapper = styled.div``;
