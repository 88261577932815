import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiExpansionPanelSummary-content": {
      margin: "27px 0 27px 24px !important",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function TableWithAccordion({ caseDetails, id }) {
  const classes = useStyles();

  const columns = [
    {
      field: "name",
      title: "Agent Name",
      sorting: false,
    },
    {
      field: "email",
      title: "Email",
      sorting: false,
      // render: (rowData) => (
      //   <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
      // ),
    },
    {
      field: "mobile",
      title: "Phone",
      sorting: false,
    },
  ];

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeading>View Only User</AccordionHeading>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          {caseDetails?.passiveNeutrals?.length ? (
            <CustomTable
              pluralTitle="Respondent"
              singularTitle="Respondent"
              {...{
                columns,
              }}
              data={caseDetails?.passiveNeutrals}
              noToolbar
              pageSize={1000}
              hidePagination={true}
            />
          ) : (
            <div className="no-result">No Respondent Found</div>
          )}
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default TableWithAccordion;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: 780px) {
    flex-direction: column;
    align-items: start;
  }
`;
