import React, { useState, useEffect } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { navigate } from "@reach/router";
import _ from "lodash";
import { Formik } from "formik";
import { HyperLink } from "../../../styles/component/style";
import { PrimaryCTAButton } from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import SystemService from "../../../services/SystemService";
import { getErrorMessage } from "../../../helpers/functions";
import { FormLabel } from "@material-ui/core";
import { CustomInputField } from "../../common/FormInputs";
import labels from "../../../helpers/labels.json";

const Index = () => {
  const breadcrumbs = [
    _.startCase(labels.configurations),
    labels.update_terminology,
  ];
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [systemConfig, setSystemConfig] = useState({
    mobile: "",
    tutorialUrl: "",
    faq: [],
    terminology: [],
  });

  useEffect(() => {
    async function getSystemConfig() {
      try {
        setLoader({ state: true, message: "Fetching Configurations..." });
        const response = await SystemService.getConfig();
        if (response.systemConfig) {
          setSystemConfig(response.systemConfig);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    getSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "configurations":
        navigate("/dashboard/configurations");
        break;
      default:
        break;
    }
  };

  function addSection(setFieldValue, sections) {
    let newSections = [...sections, [""]];
    setFieldValue("sections", newSections);
  }

  function addItem(setFieldValue, sections, index) {
    let newSections = [...sections];
    newSections[index] = [...sections[index], ""];
    setFieldValue("sections", newSections);
  }

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Updating Terminologies..." });
      let terminology = [];
      for (const [sec_idx, section] of values.sections.entries()) {
        for (const [item_idx, item] of _.compact(section).entries()) {
          const object = {
            snumber: `${sec_idx + 1}.${item_idx + 1}`,
            text: item,
          };
          terminology.push(object);
        }
      }
      const response = await SystemService.setConfig({
        ...systemConfig,
        terminology,
      });
      if (response.config) {
        setSystemConfig(response.config);
        enqueueSnackbar("Updated Terminologies", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  function getTerms(terms) {
    let sections = [["Term"]];
    terms.forEach((term, index) => {
      const [sec_idx, item_idx] = term?.snumber?.split(".");
      if (!sections[sec_idx - 1]) {
        sections[sec_idx - 1] = [];
      }
      sections[sec_idx - 1][item_idx - 1] = term.text;
    });
    return sections;
  }

  function removeSection(sections, index, setFieldValue) {
    let returnData = sections.filter((section, id) => id !== index);
    setFieldValue("sections", returnData);
  }

  function removeItem(sections, sec_index, item_index, setFieldValue) {
    try {
      let returnData = [...sections];
      returnData[sec_index] = sections[sec_index]?.filter(
        (item, id) => id !== item_index
      );
      setFieldValue("sections", returnData);
    } catch (error) {}
  }

  return (
    <>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container>
        <Formik
          initialValues={{
            sections: getTerms(systemConfig.terminology),
          }}
          onSubmit={onFormSubmit}
          validateOnBlur
          enableReinitialize
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {values.sections.map((section, sec_index) => (
                <Section key={sec_index}>
                  <Width
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <H2>
                      {labels.section} {sec_index + 1}
                    </H2>
                    {values.sections.length > 1 && (
                      <img
                        onClick={() =>
                          removeSection(
                            values.sections,
                            sec_index,
                            setFieldValue
                          )
                        }
                        src={require("../../../assets/images/removeIcon.svg")}
                        alt="remove"
                        style={{
                          position: "absolute",
                          right: -40,
                          top: "45%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Width>
                  {section.map((sub_section, sub_index) => (
                    <Width key={sub_index}>
                      <FormLabel
                        style={{
                          fontFamily: theme.fonts.primaryFontSemiBold,
                          fontSize: 10,
                          color: COLORS.INPUT_LABEL,
                        }}
                      >
                        {`${sec_index + 1}.${sub_index + 1}`}
                      </FormLabel>
                      <div style={{ position: "relative" }}>
                        <CustomInputField
                          className={"input-white"}
                          autoComplete={"new-password"}
                          type={"text"}
                          value={sub_section}
                          error={
                            errors.sections?.length &&
                            errors.sections[sec_index]?.length &&
                            errors?.sections[sec_index][sub_index] &&
                            touched?.sections[sec_index][sub_index]
                          }
                          helperText={
                            errors.sections?.length &&
                            errors.sections[sec_index]?.length &&
                            touched?.sections[sec_index][sub_index] &&
                            errors?.sections[sec_index][sub_index]
                          }
                          id={`sections[${sec_index}][${sub_index}]`}
                          name={`sections[${sec_index}][${sub_index}]`}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ marginTop: 6, marginBottom: 10 }}
                        />
                        {values.sections.length === 1 &&
                        values.sections[sec_index]?.length === 1 ? null : (
                          <img
                            onClick={() =>
                              removeItem(
                                values.sections,
                                sec_index,
                                sub_index,
                                setFieldValue
                              )
                            }
                            src={require("../../../assets/images/removeIcon.svg")}
                            alt="remove"
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              right: -40,
                              top: "45%",
                              transform: "translateY(-50%)",
                            }}
                          />
                        )}
                      </div>
                    </Width>
                  ))}
                  <HyperLink
                    style={{ marginBottom: 27 }}
                    onClick={() =>
                      addItem(setFieldValue, values.sections, sec_index)
                    }
                  >
                    {labels.add_item}
                  </HyperLink>
                </Section>
              ))}

              <WrapperButtons>
                <HyperLink
                  style={{ marginBottom: 37 }}
                  onClick={() => addSection(setFieldValue, values.sections)}
                >
                  {labels.add_section}
                </HyperLink>
              </WrapperButtons>
              <div style={{ width: 349, height: 48 }}>
                <PrimaryCTAButton onClick={handleSubmit}>
                  {labels.save_changes}
                </PrimaryCTAButton>
              </div>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Index;

const Container = styled.div`
  padding: 42px 46px;
  display: flex;
  flex-direction: column;
`;
const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  margin-bottom: 16px;
`;
const Section = styled.div`
  margin-top: 7px;
`;
const Width = styled.div`
  width: 558px;
`;
const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
