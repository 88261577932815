import React, { Fragment, useRef, useState } from "react";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import queryString from "query-string";
import labels from "../../../helpers/labels.json";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { PrimaryCTAButton } from "../../common/Buttons";
import DrawerRecording from "../ViewMeeting/ViewMeeting.js";
import useLoader from "../../../hooks/useLoader";
import theme from "../../../assets/theme";

const CaseManagerTable = () => {
  const breadcrumbs = [_.startCase(labels.server_room)];
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [opendrawer, setOpenDrawer] = useState(false);
  const [recordingDetail, setRecordingDetail] = useState();
  const [refreshDetails, setRefreshDetails] = useState(true);
  const placeholderText = "Search Cases";

  async function getServerList(query) {
    try {
      setLoader({ state: true, message: "Fetching Server Room..." });
      const response = await CaseService.getRoom(query);
      return { ...response };
    } catch (error) {
      throw error;
    } finally {
      setLoader({ state: false });
    }
  }

  async function getMeetingjoin(id) {
    try {
      const meetingurl = await CaseService.getMeeting(id);
      window.open(meetingurl, "_blank");
      return;
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }

  const columns = [
    {
      field: "id",
      title: labels["case_id"],
      sorting: false,
      tooltip: "Unique Identifier for the Case across IIAC platform",
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.caseId}?caseType=${rowData?.resolutionKind}`
            )
          }
        >
          {rowData.caseId}
        </HyperLink>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "title",
      title: "Case Title",
      render: (rowData) => (
        <Bold
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.caseId}?caseType=${rowData?.resolutionKind}`
            )
          }
        >
          {/* {rowData.title} */}
          {rowData?.title?.length > 48 ? (
            <div
              style={{
                inlineSize: 100,
                overflowWrap: "break-word",
                textTransform: "capitalize",
              }}
            >
              {rowData?.title}
            </div>
          ) : (
            <div style={{ textTransform: "capitalize" }}>{rowData?.title}</div>
          )}
        </Bold>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
      sorting: false,
    },
    {
      field: "participants",
      title: labels["participants"],
      sorting: false,
      render: (rowData) => (
        <div style={{ marginLeft: 10 }}>
          {rowData?.participantCount ? rowData?.participantCount : "0"}
        </div>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "resolutionKind",
      title: labels["resolutionKind"],
      sorting: false,
      render: (rowData) => <div>{_.capitalize(rowData?.resolutionKind)}</div>,
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "status",
      title: labels["status"],
      sorting: false,
      render: (rowData) => (
        <HyperLink
          style={{ textTransform: "capitalize" }}
          onClick={() =>
            navigate(`/dashboard/server-room/session-management`, {
              state: rowData,
            })
          }
        >
          {rowData?.running === true ? "running" : "Not running"}
        </HyperLink>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "meetingStatus",
      title: labels["meetingStatus"],
      sorting: false,
      render: (rowData) => (
        <div style={{ textTransform: "capitalize" }}>
          {rowData?.meetingStatus === "enabled"
            ? rowData?.meetingStatus
            : "disabled"}
        </div>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "meetingRecordingStatus",
      title: "Recording Status",
      sorting: false,
      render: (rowData) => (
        <div style={{ marginLeft: 15, textTransform: "capitalize" }}>
          {rowData?.meetingRecordingStatus === "yes" ? "enabled" : "disabled"}
        </div>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "Recordings",
      title: labels["meetingRecordingStatus"],
      sorting: false,
      render: (rowData) => (
        <HyperLink
          style={{ paddingLeft: "29px" }}
          onClick={() => {
            setOpenDrawer((a) => ({ ...a, state: true }));
            setRecordingDetail(rowData);
          }}
        >
          {rowData?.recordings?.count ? "View" : "No"}
        </HyperLink>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "Join",
      title: labels["meetingControlstatus"],
      sorting: false,
      render: (rowData) => (
        <PrimaryCTAButton
          style={{ height: 35 }}
          onClick={() => getMeetingjoin(rowData?.caseId)}
        >
          {"Join"}
        </PrimaryCTAButton>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.search) {
        params.search = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getServerList(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs }} />
      <Padding>
        <CustomTable
          hidePagination={state.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle={labels.server_room}
          hideFilterBar
          singularTitle=""
          {...{
            columns,
            data,
            MTRef,
            placeholderText,
          }}
          // noToolbar
          state={state}
        />
      </Padding>
      <DrawerRecording
        {...{
          opendrawer,
          setOpenDrawer,
          MTRef,
          recordingDetail,
          refreshDetails,
          setRefreshDetails,
          setRecordingDetail,
        }}
        id={recordingDetail?.caseId}
        type={recordingDetail?.resolutionKind}
      />
    </Fragment>
  );
};

export default CaseManagerTable;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
`;

const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
