import { Modal } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Formik } from "formik";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";

const Index = ({ state, setState, MTRef }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "update Time management..." });
      const payload = {
        internalMeetingId: state?.meta?.internalMeetingId,
        meetingId: state?.meta?.meetingId,
        participantNames: values?.participantNames,
        stage: values?.stage,
        venue: values?.venue,
      };
      const response = await CaseService.updateMeetingDetails(payload);
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setState({ state: false });
    }
  }

  return (
    <Modal open={state?.state} onClose={() => setState({ state: false })}>
      <DrawerContainer>
        <Container>
          <HeadingContainer>
            <Heading>{"Time Management"}</Heading>
            <CloseModal
              onClick={() => setState({ state: false })}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <Formik
            initialValues={{}}
            enableReinitialize
            validateOnBlur
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              handleBlur,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                <FormContainer>
                  <LabelContainer>
                    <LabelValueContainer>
                      <Label>{"Internal Meeting Id"}</Label>
                      <Value>{state?.meta?.internalMeetingId}</Value>
                    </LabelValueContainer>
                  </LabelContainer>
                  <BasicDetails>
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "120px" }}>
                        <Label>{"Date Of Hearing"}</Label>
                        <Value>
                          {state?.meta?.dateOfHearing
                            ? state?.meta?.dateOfHearing
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "130px" }}>
                        <Label>{"Session StartTime"}</Label>
                        <Value>
                          {state?.meta?.sessionStartTime
                            ? state?.meta?.sessionStartTime
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "120px" }}>
                        <Label>{"Session EndTime"}</Label>
                        <Value>
                          {state?.meta?.sessionEndTime
                            ? state?.meta?.sessionEndTime
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "130px" }}>
                        <Label>{"Recording StartTime"}</Label>
                        <Value>
                          {state?.meta?.recordingStartTime
                            ? state?.meta?.recordingStartTime
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "120px" }}>
                        <Label>{"Recording EndTime"}</Label>
                        <Value>
                          {state?.meta?.recordingEndTime
                            ? state?.meta?.recordingEndTime
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "150px" }}>
                        <Label>{"Transcription StartTime"}</Label>
                        <Value>
                          {state?.meta?.transcriptionStartTime
                            ? state?.meta?.transcriptionStartTime
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer style={{ width: "140px" }}>
                        <Label>{"Transcription EndTime"}</Label>
                        <Value>
                          {state?.meta?.transcriptionEndTime
                            ? state?.meta?.transcriptionEndTime
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Session Duration"}</Label>
                        <Value>
                          {state?.meta?.sessionDuration
                            ? state?.meta?.sessionDuration
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Recording Duration"}</Label>
                        <Value>
                          {state?.meta?.recordingDuration
                            ? state?.meta?.recordingDuration
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Transcription Duration"}</Label>
                        <Value>
                          {state?.meta?.transcriptionDuration
                            ? state?.meta?.transcriptionDuration
                            : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Stage"}</Label>
                        <Value>
                          {state?.meta?.stage ? state?.meta?.stage : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Venue"}</Label>
                        <Value>
                          {state?.meta?.venue ? state?.meta?.venue : "-"}
                        </Value>
                      </LabelValueContainer>
                    </LabelContainer>
                  </BasicDetails>
                  <LabelContainer>
                    <LabelValueContainer>
                      <Label>{"Participants"}</Label>
                      <Value>
                        {state?.meta?.participantNames
                          ? state?.meta?.participantNames
                          : "-"}
                      </Value>
                    </LabelValueContainer>
                  </LabelContainer>
                </FormContainer>
              </StyledForm>
            )}
          </Formik>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default Index;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 50%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  line-height: 1.71;
  max-height: 385px;
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const StyledForm = styled.form`
  width: 100%;
`;

const BasicDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
`;

const LabelContainer = styled.div`
  margin-right: 25px;
  margin-bottom: 20px;
  @media (min-width: 640px) {
    margin-right: 0;
  }
`;

const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.9;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;

const Value = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
  word-break: break-all;
  padding-right: 6px;
`;
