import { Modal } from "@material-ui/core";
import React from "react";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import styled from "styled-components";
import { CheckboxContainer, CustomCheckBox } from "../../common/FormInputs";

const Settings = ({
    showSettings,
    setShowSettings,
    isPauseWhileTypingOn,
    showTimecodes,
    showSpeakers,
    previewIsDisplayed,
    handlePauseWhileTyping,
    handleShowSpeakers,
    handlePreviewIsDisplayed,
    handleShowTimecodes,
    mediaType
}) => {
    return (
        <>
            <Modal open={showSettings}
                onClose={() => setShowSettings(false)}
            >
                <DrawerContainer>
                    <HeadingContainer>
                        <Heading>{"Settings"}</Heading>
                        <CloseModal
                            onClick={() => setShowSettings(false)}
                            src={require("../../../assets/images/closeModal.svg")}
                        />
                    </HeadingContainer>
                    <NoteContainer>
                        <ButtonWrapper>
                            <Label>{"Pause while typing"}</Label>
                            <CheckboxContainer>
                                <CustomCheckBox
                                    checked={isPauseWhileTypingOn}
                                    label={'Pause while typing'}
                                    onChange={(e) => handlePauseWhileTyping(e)}
                                />
                            </CheckboxContainer>
                        </ButtonWrapper>
                        {/* <ButtonWrapper>
                            <Label>{"Rollback Interval (sec)"}</Label>
                            <CustomInputField
                                variant="outlined"
                                value={rollBackValueInSeconds}
                                onChange={(e) => handleRollBackValueInSeconds(e)}
                            />
                        </ButtonWrapper> */}
                        <ButtonWrapper>
                            <Label>{"Show TimesCodes"}</Label>
                            <CheckboxContainer>
                                <CustomCheckBox
                                    checked={showTimecodes}
                                    label={'Hide TimeCodes'}
                                    onChange={(e) => handleShowTimecodes(e)}
                                />
                            </CheckboxContainer>
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <Label>{"Show Speaker Labels"}</Label>
                            <CheckboxContainer>
                                <CustomCheckBox
                                    checked={showSpeakers}
                                    label={'Hide Speaker Labels'}
                                    onChange={(e) => handleShowSpeakers(e)}
                                />
                            </CheckboxContainer>
                        </ButtonWrapper>
                        {mediaType === "video" ?
                            <ButtonWrapper>
                                <Label>{"Display Preview"}</Label>
                                <CheckboxContainer>
                                    <CustomCheckBox
                                        checked={previewIsDisplayed}
                                        label={'Display Preview'}
                                        onChange={(e) => handlePreviewIsDisplayed(e)}
                                    />
                                </CheckboxContainer>
                            </ButtonWrapper>
                            : null}
                    </NoteContainer>
                </DrawerContainer>
            </Modal>
        </>
    )
}

export default Settings;

const ButtonWrapper = styled.div`
  display: flex;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  line-height: 2.7;
  width: 250px;
  color: ${COLORS.COLOR_DARK};
`;

const DrawerContainer = styled.div`
  max-width: 346px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 33px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.p`
  padding-top: 14px;
  padding-left: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  overflow: auto;
`;
