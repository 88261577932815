import React from "react";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import { Formik } from "formik";
import { PrimaryCTAButton } from "../../../common/Buttons";
import { CustomRadioGroup } from "../../../common/FormInputs";

const PartyVerification = ({ systemConfig, handleSystemConfigChange }) => {
  return (
    <DrawerContainer role="presentation">
      <Container>
        <FormContainer>
          <Formik
            initialValues={{
              partyVerificationMethod:
                systemConfig?.partyVerificationMethod || "manual",
            }}
            onSubmit={({ partyVerificationMethod }) =>
              handleSystemConfigChange({
                ...systemConfig,
                partyVerificationMethod,
              })
            }
            validateOnBlur
            enableReinitialize
            validateOnChange
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              isValid,
            }) => (
              <StyledForm>
                <StyledFormElementsWrapper1
                  style={{ marginBottom: 15, marginTop: 15 }}
                >
                  <CustomRadioGroup
                    radioInputs={[
                      { label: "Manual", value: "manual" },
                      { label: "Automatic", value: "automatic" },
                    ]}
                    name={"partyVerificationMethod"}
                    handleChange={(e) =>
                      handleChange(e, "partyVerificationMethod")
                    }
                    {...{ values }}
                  />
                </StyledFormElementsWrapper1>
                <ButtonContainer style={{ marginBottom: 13 }}>
                  <PrimaryCTAButton
                    type="submit"
                    onClick={handleSubmit}
                    style={{ width: "30%", height: 35 }}
                  >
                    Update
                  </PrimaryCTAButton>
                </ButtonContainer>
              </StyledForm>
            )}
          </Formik>
        </FormContainer>
      </Container>
    </DrawerContainer>
  );
};

export default PartyVerification;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;

  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 450px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 18px;
  justify-content: flex-end;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const FormContainer = styled.div`
  padding: 15px;
  .link-container {
    margin: 12px;
  }
  .date-time {
    display: flex;
    .date {
      margin: 12px;
      width: 100%;
    }
    .time {
      margin: 12px;
      width: 100%;
    }
  }
`;

const StyledFormElementsWrapper1 = styled.div`
  margin-bottom: 28px;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
  & .mediator-type {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column !important;
      & .MuiFormControlLabel-root {
        padding: 10px 0px;
      }
    }
  }
`;
