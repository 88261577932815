import React, { useState } from "react";

export const CartCountContext = React.createContext();

function CartCountProvider({ children }) {
  const [triggerCount, setTriggerCount] = useState(true);
  const [cartCount, setCartCount] = useState(true);
  const [stats, setStats] = useState({});

  const value = React.useMemo(() => {
    return {
      triggerCount,
      setTriggerCount,
      cartCount,
      setCartCount,
      stats,
      setStats,
    };
  }, [triggerCount, setTriggerCount, cartCount, setCartCount, stats, setStats]);

  return (
    <CartCountContext.Provider value={value}>
      {children}
    </CartCountContext.Provider>
  );
}

export default CartCountProvider;
