import { Modal } from "@material-ui/core";
import React from "react";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import styled from "styled-components";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";

const Export = ({
  showExportOptions,
  setShowExportOptions,
  meetingId,
  transcriptId,
}) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const downloadExportMeetingId = async (meetingId, format) => {
    try {
      setLoader({ state: true, message: "download transcript" });
      const payload = {
        meetingId: meetingId,
        fileFormat: format,
      };
      const response = await CaseService.downloadTranscripts(payload);
      if (response) {
        enqueueSnackbar("download successful", {
          variant: "success",
        });
      }
      window.open(response?.fileUrl);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setShowExportOptions(false);
    }
  };

  const downloadExportTranscriptId = async (transcriptId, format) => {
    try {
      setLoader({ state: true, message: "download transcript" });
      const payload = {
        transcriptId: transcriptId,
        fileFormat: format,
      };
      const response = await CaseService.downloadTranscripts(payload);
      if (response) {
        enqueueSnackbar("download successful", {
          variant: "success",
        });
      }
      window.open(response?.fileUrl);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setShowExportOptions(false);
    }
  };

  return (
    <>
      <Modal
        open={showExportOptions}
        onClose={() => setShowExportOptions(false)}
      >
        <DrawerContainer>
          <HeadingContainer>
            <Heading>{"Exports"}</Heading>
            <CloseModal
              onClick={() => setShowExportOptions(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <NoteContainer>
            <ButtonWrapper>
              <HyperLink
                onClick={() =>
                  meetingId
                    ? downloadExportMeetingId(meetingId, "wordWithLineNumbers")
                    : downloadExportTranscriptId(
                        transcriptId,
                        "wordWithLineNumbers"
                      )
                }
              >
                {"Word With Line Numbers"}
              </HyperLink>
            </ButtonWrapper>
            <ButtonWrapper>
              <HyperLink
                onClick={() =>
                  meetingId
                    ? downloadExportMeetingId(
                        meetingId,
                        "wordWithOutLineNumbers"
                      )
                    : downloadExportTranscriptId(
                        transcriptId,
                        "wordWithOutLineNumbers"
                      )
                }
              >
                {"Word With Out Line Numbers"}
              </HyperLink>
            </ButtonWrapper>
            <ButtonWrapper>
              <HyperLink
                onClick={() =>
                  meetingId
                    ? downloadExportMeetingId(meetingId, "text")
                    : downloadExportTranscriptId(transcriptId, "text")
                }
              >
                {"Text"}
              </HyperLink>
            </ButtonWrapper>
          </NoteContainer>
        </DrawerContainer>
      </Modal>
    </>
  );
};

export default Export;

const ButtonWrapper = styled.div`
  display: flex;
  line-height: 2.7;
`;

const DrawerContainer = styled.div`
  max-width: 346px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 33px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.p`
  padding-top: 14px;
  padding-left: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  overflow: auto;
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-left: 0px;
`;
