import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import { PrimaryOutlinedCTAButton, PrimaryCTAButton } from "../Buttons";
import { CustomInputField } from "../FormInputs";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";

export default function DescriptionModal({
  modal = { state: false },
  setModal,
  values,
  setRefreshDetails,
  readOnly,
  isActionsDisabled
}) {
  const [text, setText] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [disable, setDisable] = useState(readOnly);
  const id = modal?.id;

  useEffect(() => {
    if (!modal.state) {
      setText("");
      setDisable(true);
    } else {
      const newText = readOnly
        ? modal.des
        : values.claim_items[modal.index].description;
      if (newText) {
        setText(newText);
      }
    }
  }, [readOnly, modal.des, modal.state, modal.index, values]);

  const addClaimDescription = async text => {
    try {
      setLoader({ state: true, message: "Update Description..." });
      const payload = {
        reason: text
      };
      const res = await CaseService.updateClaimDescription(id, payload);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success"
        });
        setRefreshDetails(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <Drawer anchor={"right"} open={modal.state}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>CLAIM ITEM DESCRIPTION</Heading>
          <CloseModal
            onClick={() => setModal({ state: false })}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <BasicDetailsLabel>DESCRIPTION</BasicDetailsLabel>
          {modal.state && (
            <CustomInputField
              variant="outlined"
              className={"input-height-auto"}
              style={{ marginTop: 6 }}
              multiline={true}
              rows={5}
              maxRows={5}
              name={modal.name}
              value={text}
              disabled={disable ? true : false}
              onChange={e => setText(e.target.value)}
            />
          )}

          <Row>
            <PrimaryOutlinedCTAButton
              onClick={() => setModal({ state: false })}
              style={{ width: "47%" }}
            >
              Back
            </PrimaryOutlinedCTAButton>
            {!isActionsDisabled ? (
              disable ? (
                <PrimaryOutlinedCTAButton
                  onClick={() => {
                    setDisable(false);
                  }}
                  style={{ width: "47%" }}
                >
                  Edit Description
                </PrimaryOutlinedCTAButton>
              ) : (
                <PrimaryCTAButton
                  onClick={() => {
                    // setFieldValue(modal.name, text);
                    addClaimDescription(text);
                    setModal({ state: false });
                  }}
                  style={{ width: "47%" }}
                >
                  Save
                </PrimaryCTAButton>
              )
            ) : null}
            {/* {!readOnly && (
              <PrimaryCTAButton
                onClick={() => {
                  setFieldValue(modal.name, text);
                  setModal({ state: false });
                }}
                style={{ width: "47%" }}
              >
                SAVE
              </PrimaryCTAButton>
            )} */}
          </Row>
        </AgentFormContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 555px;
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 26px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  height: 100%;
  & form {
    padding-top: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

const BasicDetailsLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-transform: uppercase;
`;
