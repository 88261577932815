import API from "../api/manager";
import config from "../api/config";

const getCMlist = (query) => {
  return API({
    method: "GET",
    url: config.urls.casemanager.index + query,
  });
};

const getCMDetails = (id) => {
  return API({
    method: "GET",
    url: config.urls.casemanager.index + `/${id}`,
  });
};

const createCaseManager = (data) => {
  return API({
    method: "POST",
    url: config.urls.casemanager.index,
    data,
  });
};

const editCaseManager = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.casemanager.index + `/${id}`,
    data,
  });
};

const getCaseListing = (query) => {
  return API({
    method: "GET",
    url: config.urls.casemanager.cases + query,
  });
};

const deleteCaseManager = (id, status) => {
  return API({
    method: "DELETE",
    url: config.urls.casemanager.index + `/${id}/${status}`,
  });
};

const getCaseStats = (query) => {
  return API({
    method: "GET",
    url: config.urls.casemanager.stats + query,
  });
};

export default {
  getCMlist,
  getCMDetails,
  getCaseListing,
  getCaseStats,
  createCaseManager,
  editCaseManager,
  deleteCaseManager,
};
