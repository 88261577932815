import React from "react";
import Day from "./Day";
import styled from "styled-components";
export default function Month({ month }) {
  return (
    <Container>
      {month.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((day, idx) => (
            <Day day={day} key={idx} rowIdx={i} />
          ))}
        </React.Fragment>
      ))}
    </Container>
  );
}

const Container = styled.div`
  padding-left: 20px;
  padding-right: 25px;
  padding-top: 10px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 1fr));
  margin-right: 10px;
  margin-bottom: 15px;
`;
