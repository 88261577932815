import API from "../api/manager";
import config from "../api/config";

const login = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.login,
      data,
    },
    false
  );
};

const profile = () => {
  return API({
    method: "GET",
    url: config.urls.auth.profile,
  });
};

export default {
  login,
  profile,
};
