import React from "react";
import DocViewer from "@cyntler/react-doc-viewer";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { Modal } from "@material-ui/core";

const DocViewers = ({ docview, setDocView }) => {
  return (
    <>
      <Modal open={docview?.state} onClose={() => setDocView({ state: false })}>
        <DrawerContainer role="presentation">
          <HeadingContainer>
            <Heading>{docview.title}</Heading>
            <CloseModal
              onClick={() => setDocView({ state: false })}
              src={require("../../../assets/images/closeButton.svg")}
            />
          </HeadingContainer>
          <Container>
            <DocViewer
              className="responsive"
              prefetchMethod="GET"
              documents={[{ uri: docview?.url }]}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: false,
                  retainURLParams: false,
                },
              }}
              style={{ height: 585, borderRadius: "8px" }}
            />
          </Container>
        </DrawerContainer>
      </Modal>
    </>
  );
};
export default DocViewers;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 80%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 24px;
  height: 22px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 12px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 34px;
    height: 24px;
    right: 10px;
  }
`;

const Heading = styled.h3`
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;
