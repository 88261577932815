import React from "react";
import TimeSlideTranscript from "../../internal/TimeSlideTranscript/SplitTranscription";

const AudioTranscription = ({ id, type }) => {
  return (
    <div>
      <TimeSlideTranscript id={id} type={type} />
    </div>
  );
};

export default AudioTranscription;
