import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import useLoader from "../../../hooks/useLoader";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    minWidth: 169,
    minHeight: 169,
    maxWidth: 250,
    justifyContent: "center",
    alignItems: "center",
  },
}))(MuiDialogContent);

const useStyles = makeStyles(() => ({
  loaderText: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    fontSize: 12,
    color: COLORS.COLOR_DARK,
    textAlign: "center",
    marginTop: 11,
  },
  loader: {
    width: 50,
    height: 50,
    margin: "0 auto",
  },
  paper: {
    borderRadius: 16,
    boxShadow: "none",
  },
}));

export default function Loader() {
  const { loader } = useLoader();
  const classes = useStyles();
  return (
    <Dialog
      disableBackdropClick
      aria-labelledby="customized-dialog-title"
      open={loader.state}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.78)",
        },
      }}
      classes={{ paper: classes.paper }}
    >
      <DialogContent dividers>
        <img
          alt="loading"
          className={classes.loader}
          src={require("../../../assets/images/loading.svg")}
        />
        <Typography className={classes.loaderText} gutterBottom>
          {loader.message ? loader.message : "Please wait. Loading"}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
