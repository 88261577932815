import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core";
import COLORS from "../assets/Colors";
import UserProvider from "./UserProvider";
import LoaderProvider from "./LoaderProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import AlertProvider from "./AlertProvider";
import { SnackbarButton } from "../styles/component/style";
import CartCountProvider from "./cartCountProvider";
import SettingsProvider from "./SettingsProvider";
import DictationProvider from "./OrderDictationProvider";
import CalendarProvider from "./CalendarProvider";

const useStyles = makeStyles(() => ({
  success: { backgroundColor: COLORS.BTN_GREEN },
}));

export default function Providers({ children }) {
  const classes = useStyles();
  const snackBarRef = useRef();

  return (
    <SnackbarProvider
      ref={snackBarRef}
      maxSnack={1}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      action={(key) => (
        <SnackbarButton
          onClick={() => snackBarRef?.current?.closeSnackbar(key)}
        >
          Dismiss
        </SnackbarButton>
      )}
      classes={{ variantSuccess: classes.success }}
      autoHideDuration={4000}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SettingsProvider>
          <AlertProvider>
            <DictationProvider>
              <CalendarProvider>
                <LoaderProvider>
                  <UserProvider>
                    <CartCountProvider>{children}</CartCountProvider>
                  </UserProvider>
                </LoaderProvider>
              </CalendarProvider>
            </DictationProvider>
          </AlertProvider>
        </SettingsProvider>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  );
}
