import React from "react";
import CreateCaseManager from "../CreateCaseManager";

const EditCaseManager = ({ id }) => {
  return (
    <div>
      <CreateCaseManager id={id} />
    </div>
  );
};

export default EditCaseManager;
