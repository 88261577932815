import React from "react";
import styled from "styled-components";
import { Tooltip, Checkbox } from "@material-ui/core";

const toolTipIcon = require("../../../../assets/images/white_tooltip.png");
const sortIcon = require("../../../../assets/images/sortIcon.svg");

const CustomHeader = ({ MTRef, ...props }) => {
  function handleSort(column) {
    if (column.hasOwnProperty("sorting") && !column.sorting) {
      return false;
    }
    const oldQuery = MTRef?.current?.state?.query;
    if (oldQuery) {
      const orderBy = { field: column.field };
      let orderDirection = "asc";
      if (oldQuery.orderBy?.field && oldQuery.orderBy?.field === column.field) {
        orderDirection = oldQuery.orderDirection === "asc" ? "desc" : "asc";
      }
      const newQuery = { ...oldQuery, orderBy, orderDirection };
      MTRef.current.onQueryChange(newQuery);
    }
  }
  return (
    <thead className="MuiTableHead-root">
      <TableRow className="MuiTableRow-root MuiTableRow-head">
        {props.hasSelection ? (
          <TH
            style={{
              minWidth: 60,
            }}
          >
            <Checkbox
              style={{ backgroundColor: "#293461" }}
              onChange={(e) => props.onAllSelected(e.target.checked)}
              checked={props.dataCount === props.selectedCount}
              icon={
                <img
                  alt="checked"
                  src={require("../../../../assets/images/checkboxWhite.svg")}
                />
              }
              checkedIcon={
                <img
                  alt="checked"
                  src={require("../../../../assets/images/checkBoxSelectedWhite.svg")}
                />
              }
            />
          </TH>
        ) : null}
        {props.columns.map((column) => (
          <TH
            {...{ column }}
            key={column.title}
            // cursor={
            //   (column.hasOwnProperty("sorting") && column?.sorting) ||
            //   !column.hasOwnProperty("sorting")
            //     ? "pointer"
            //     : "inherit"
            // }
            onClick={() => handleSort(column)}
            className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignLeft"
          >
            <>
              {column.title}
              {column.tooltip && (
                <Tooltip
                  style={{ position: "relative", top: 3 }}
                  title={column.tooltip}
                  placement="top"
                >
                  <TooltipIcon src={toolTipIcon} />
                </Tooltip>
              )}
              {column.hasOwnProperty("sorting") ? (
                !column.sorting ? null : (
                  <SortIcon src={sortIcon} />
                )
              ) : (
                <SortIcon src={sortIcon} />
              )}
            </>
          </TH>
        ))}
        {props.hasDetailPanel && <TH />}
      </TableRow>
    </thead>
  );
};

export default CustomHeader;

const TooltipIcon = styled.img`
  /* width: 17px; */
  height: 17px;
  margin-left: 9px;
  cursor: pointer;
  @media (max-width: 1440px) {
    width: 14px;
    height: 14px;
  }
`;

const SortIcon = styled.img`
  width: 8px;
  height: 8px;
  margin-left: 9px;
  object-fit: contain;
  cursor: pointer;
`;

const TH = styled.th`
  box-sizing: border-box;
  white-space: nowrap;
  cursor: ${({ cursor }) => cursor};
  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;

const TableRow = styled.tr`
  max-height: 44px !important;
`;
