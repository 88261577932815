import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";

var icons = ReactQuill.Quill.import("ui/icons");
icons[
  "bold"
] = `<img src=${require("../../../assets/images/editor/bold.svg")} aria-hidden="true" />`;
icons[
  "italic"
] = `<img src=${require("../../../assets/images/editor/italics.svg")} aria-hidden="true" />`;
icons[
  "underline"
] = `<img src=${require("../../../assets/images/editor/underline.svg")} aria-hidden="true" />`;
icons[
  "blockquote"
] = `<img src=${require("../../../assets/images/editor/blockQuote.svg")} aria-hidden="true" />`;
icons["align"][
  ""
] = `<img src=${require("../../../assets/images/editor/leftAlign.svg")} aria-hidden="true" />`;
icons["align"][
  "center"
] = `<img src=${require("../../../assets/images/editor/centerAlign.svg")} aria-hidden="true" />`;
icons["align"][
  "right"
] = `<img src=${require("../../../assets/images/editor/rightAlign.svg")} aria-hidden="true" />`;

const modules = {
  toolbar: [
    [
      "bold",
      "italic",
      "underline",
      "blockquote",
      "link",
      { list: "ordered" },
      { list: "bullet" },
      { align: "" },
      { align: "center" },
      { align: "right" },
    ],
  ],
};

export default function ReactQuillEditor({
  value = "",
  onChange = () => {},
  placeholder = "",
  disabled = false,
}) {
  return (
    <EditorContainer>
      <ReactQuill
        onChange={onChange}
        modules={modules}
        value={value}
        placeholder={placeholder}
        readOnly={disabled}
      />
    </EditorContainer>
  );
}

const EditorContainer = styled.div`
  width: 100%;
  .ql-editor {
    font-size: 16px;
  }
  .ql-snow.ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    button {
      margin: 0px 10px;
      &.ql-list {
        height: 28px;
      }
    }
  }
  .ql-container {
    height: 150px;
    background: ${COLORS.PRIMARY_WHITE};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
