import React, { useRef, useState } from "react";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import _ from "lodash";
import queryString from "query-string";
import labels from "../../../helpers/labels.json";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import useLoader from "../../../hooks/useLoader";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import theme from "../../../assets/theme";
import { navigate } from "@reach/router";

const MeetingAudioTable = ({ id, type }) => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function getAudioDetails(query) {
    try {
      setLoader({ state: true, message: "Fetching Audio Details..." });
      const response = await CaseService.getAudioDetails(id, query);
      return { ...response };
    } catch (error) {
      throw error;
    } finally {
      setLoader({ state: false });
    }
  }

  const columns = [
    {
      field: "id",
      title: "Id",
      sorting: false,
      render: (rowData) => <b>{rowData?.id}</b>,
      // headerStyle: {
      //   padding: `12px 16px 12px 40px`,
      // },
      // cellStyle: {
      //   padding: `12px 16px 12px 40px`,
      // },
    },
    {
      field: "audioFileName",
      title: labels["audioFileName"],
      sorting: false,
      render: (rowData) => (
        <div
          style={{
            color: COLORS.COLOR_DARK,
            fontFamily: theme.fonts.primaryFontSemiBold,
          }}
        >
          {rowData?.audioFileName.split(".mp3")}
        </div>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "audioFileUrl",
      title: "Live Transcript",
      sorting: false,
      render: (rowData, index) =>
        rowData?.transcriptionData ? (
          <HyperLink
            onClick={() =>
              navigate(
                `/dashboard/cases/${id}/split-transcription?caseType=${type}`,
                {
                  state: rowData,
                }
              )
            }
          >
            {rowData?.audioFileName}
          </HyperLink>
        ) : (
          <div>{rowData?.audioFileName}</div>
        ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "audioFileUrl",
      title: labels["audioFileUrl"],
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() => {
            window.open(rowData?.audioFileUrl);
          }}
        >
          {rowData?.audioFileName.split(".mp3")}
        </HyperLink>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "duration",
      title: labels["duration"],
      sorting: false,
      render: (rowData) => (
        <b style={{ marginLeft: 30 }}>{rowData?.duration}</b>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "created_at",
      title: "Created",
      sorting: false,
      render: (rowData) => <b>{rowData?.created_at}</b>,
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getAudioDetails(stringParams)
        .then(async (result) => {
          if (result) {
            result = result?.meetingAudioDetails;
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <>
      <AccordionDetails>
        <TableContainer>
          <CustomTable
            hidePagination={state.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle={labels.audioDetails}
            hideFilterBar
            singularTitle=""
            {...{
              columns,
              data,
              MTRef,
            }}
            noToolbar
            state={state}
          />
        </TableContainer>
      </AccordionDetails>
    </>
  );
};

export default MeetingAudioTable;

export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

// const Bold = styled.span`
//   cursor: pointer;
//   font-family: ${theme.fonts.primaryFontSemiBold};
// `;
