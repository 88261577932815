import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";

export const ProfilePic = styled.img`
  width: 91px;
  height: 91px;
  object-fit: cover;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 2px;
  position: relative;
`;

export const Container = styled.div`
  max-width: 1300px;
  /* margin: 0 auto; */
  padding: 20px 53px;
`;

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;
export const Value = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
  word-break: break-all;
  padding-right: 6px;
`;

export const Row = styled.div`
  width: calc(1100px / 2);
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;

export const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const BoldLabel = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
  text-transform: uppercase;
  margin-top: 58px;
  margin-bottom: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const ProfilePicContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

export const Grid = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 30px;
  grid-gap: 15px;
`;
