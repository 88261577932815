import { useContext } from "react";
import { SettingsContext } from "../providers/SettingsProvider";

export default function useSettings() {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error("useSettings must be used within a settingsprovider");
  }
  return context;
}
