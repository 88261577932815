import React from "react";
import TimeSlideTranscript from "../../internal/TimeSlideTranscript/index";

const EditTranscript = ({ id, type }) => {
  return (
    <div>
      <TimeSlideTranscript id={id} type={type} />
    </div>
  );
};

export default EditTranscript;
