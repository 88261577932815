import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import COLORS from "../../../assets/Colors";
import styled from "styled-components";
import theme from "../../../assets/theme";
import labels from "../../../helpers/labels.json";
import { useState } from "react";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import MediatorListing from "../MediatorListing";
import InvitedMediatorList from "../InvitedMediatorList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledText style={{ height: "100%" }}>{children}</StyledText>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledText = styled.article`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  overflow: auto;
`;

const useStyles = makeStyles(() => ({
  root: {
    margin: "30px 46px",
  },

  appBar: {
    color: COLORS.COLOR_DARK,
    boxShadow: "none",
    background: "transparent",
    fontFamily: theme.fonts.primaryFontBold,
  },
}));

const PaymentHistoryPage = () => {
  const breadcrumbs = [_.startCase(labels.mediator)];
  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <div className="paymentHistory">
      <ActionBar {...{ breadcrumbs }} />
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static">
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={(_, newValue) => setValue(newValue)}
            aria-label="pending-action-tab"
          >
            <Tab label={labels.activeMediator} {...a11yProps(0)} />
            <Tab label={labels.invitedMediator} {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <MediatorListing />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InvitedMediatorList />
        </TabPanel>
      </div>
    </div>
  );
};

export default PaymentHistoryPage;
