import React from "react";
import { Redirect } from "@reach/router";

export default function ProtectedRoute({ ...props }) {
  return localStorage.getItem("auth_token") ? (
    props.children
  ) : (
    <Redirect to="/" noThrow />
  );
}
