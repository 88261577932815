import React, { useState, useEffect, Fragment } from "react";
import ActionBar from "../../common/ActionBar";
import {
  Container,
  ProfilePic,
  LabelValueContainer,
  Label,
  Value,
  Grid,
} from "./styles";
import _ from "lodash";
import ProfileModal from "./components/ProfileModal";
import { navigate } from "@reach/router";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import SystemService from "../../../services/SystemService";
import labels from "../../../helpers/labels.json";
import CasesTable from "../../common/CasesTable";
import theme from "../../../assets/theme";
import PublishNeutral from "./components/PublishNeutral";

const keys1 = {
  name: labels.profile_name,
  email: labels.profile_email,
  mobile: labels.profile_mobile,
};

const keys2 = {
  qualification: labels["onboarding.qualification"],
  experience: labels["onboarding.yearsOfExperience"],
  languagesKnown: labels["onboarding.language"],
};
const keys3 = {
  affiliatedOrg: labels["onboarding.affiliatedOrg"],
  location: labels["onboarding.location"],
};

const keys4 = {
  alternateMobileNumber: labels["onboarding.alternateMobileNumber"],
  institutionOfMediationTraining: labels["onboarding.institutionOfMediationTraining"],
  numberOfMediationsConducted: labels["onboarding.numberOfMediationsConducted"],
  paymentCode: labels["onboarding.paymentCode"],
};

const Index = ({ userId }) => {
  const [modal, setModal] = useState(false);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([
    _.startCase(labels.mediator),
  ]);
  const [openPublish, setOpenPublish] = useState(false);

  useEffect(() => {
    async function getUserDetails() {
      try {
        setLoader({ state: true, message: "Fetching User Profile..." });
        const response = await SystemService.getProfile(userId);
        setUser(response);
        setBreadcrumbs((b) => [...b, response.name]);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (userId) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const actions = [
    {
      text: labels["mediator.publish"],
      props: { onClick: () => setOpenPublish(true) },
    },
    {
      text: labels.edit_mediator,
      props: { onClick: () => setModal(true) },
    },
  ];

  const onBreadcrumbClick = (selected) => {
    switch (selected) {
      case _.startCase(labels.mediator):
        navigate("/dashboard/arbitrator");
        break;
      default:
        break;
    }
  };
  if (!user) return null;
  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
        actions={actions}
      />
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "80%",
            justifyContent: "center",
          }}
        >
          <ProfilePic
            alt="profile_img"
            src={
              user?.avatarUrl
                ? user.avatarUrl
                : require("../../../assets/images/userPlaceholder.svg")
            }
          />
          {user?.tagLine ? (
            <div style={{ marginLeft: 20, maxWidth: 500, textAlign: "center" }}>
              <Label style={{ fontFamily: theme.fonts.primaryFontBoldItalic }}>
                "{user?.tagLine}"
              </Label>
            </div>
          ) : null}
        </div>
        <Grid>
          {_.keys(keys1).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys1[key]}</Label>
              <Value>{user[key] || "-"}</Value>
            </LabelValueContainer>
          ))}
          {_.keys(keys2).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys2[key]}</Label>
              <Value>{user[key]}</Value>
            </LabelValueContainer>
          ))}
          {_.keys(keys3).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys3[key]}</Label>
              <Value>{user[key]}</Value>
            </LabelValueContainer>
          ))}
          {_.keys(keys4).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys4[key]}</Label>
              <Value>{user[key]}</Value>
            </LabelValueContainer>
          ))}
        </Grid>
        <div style={{ marginTop: 30 }}>
          <LabelValueContainer key={"Specialites"}>
            <Label>{labels["onboarding.specialities"]}</Label>
            <Value>{user?.specialities?.join(", ")}</Value>
          </LabelValueContainer>
        </div>
        <div style={{ marginTop: 30 }}>
          <LabelValueContainer key={"LastHeldPosition"}>
            <Label>{labels["onboarding.lastHeldPosition"]}</Label>
            <Value>{user?.lastHeldPosition}</Value>
          </LabelValueContainer>
        </div>
        <div style={{ marginTop: 30 }}>
          <LabelValueContainer key={"Shortbio"}>
            <Label>{labels["onboarding.shortbio"]}</Label>
            <Value>{user?.shortBio}</Value>
          </LabelValueContainer>
        </div>
      </Container>
      <div>
        <CasesTable caseType="arbitration" id={userId} role="mediatorId" />
      </div>
      <ProfileModal {...{ modal, setModal, user, setUser }} />
      <PublishNeutral {...{ openPublish, setOpenPublish, user, setUser }} />
    </Fragment>
  );
};

export default Index;
