import React, { useState, useEffect } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import { getMonth } from "../component/util";
import useCalendar from "../../../hooks/useCalender";
import CalendarHeader from "../component/CalendarHeader";
import Month from "../component/Month";
import CaseService from "../../../services/CaseService";
import CauseListModal from "../component/CauseListModal";

const Index = () => {
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { setFilterEvents, monthIndex, showEventModal } = useCalendar();

  useEffect(() => {
    (async function dailyCauseList() {
      try {
        const response = await CaseService.dailyCauseList();
        if (response) {
          setFilterEvents(
            response.caseDet.map((item) => ({ ...item, color: "#00838c" })),
          );
        }
      } catch (error) {
        console.log(error);
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  return (
    <>
      <ActionBar breadcrumbs={["Daily Cause list"]} />
      <Container>
        {showEventModal?.state && <CauseListModal />}
        <CalendarHeader />
        <div className="flex flex-1">
          <Month month={currenMonth} />
        </div>
      </Container>
    </>
  );
};

export default Index;

const Container = styled.div`
  height: 80vh; /* vh = viewport height */
  display: flex;
  flex-direction: column;
`;
