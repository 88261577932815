import React from "react";
import { Header } from "../../internal/Header/Header";

export default function TemporaryRoute({
  showSidebar = true,
  selectedItem,
  selectedSubItem = "arbitration",
  children,
}) {
  return (
    <Header {...{ showSidebar, selectedItem, selectedSubItem }}>
      {children}
    </Header>
  );
}
