import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { PrimaryCTAButton } from "../../common/Buttons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Index = ({
  heading = "Enter Heading",
  StyledPara,
  StyleCard,
  terms = [
    {
      snumber: "Term 1",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      snumber: "Term 2",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ],

  button = false,
  buttonOnClick = () => {},
}) => {
  const useStyles = makeStyles((themes) => ({
    root1: {
      width: "100%",
      backgroundColor: "white",
      boxShadow: "none",
    },
    secondaryHeading: {
      maxWidth: "960px",
      width: "100%",
      fontFamily: theme.fonts.primaryFontRegular,
      fontSize: "14px",
      color: COLORS.COLOR_DARK,
      lineHeight: "1.57",
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <CardWrapper style={StyleCard}>
      <Accordion expanded={open} className={classes.root1}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => setOpen(!open)} />}
        >
          <div className="heading">
            <FlexBetween>
              <CardHeader>{heading}</CardHeader>
              {button && (
                <WrapperButton>
                  <PrimaryCTAButton
                    onClick={buttonOnClick}
                    style={{ padding: "unset", height: 35 }}
                  >
                    {button}
                  </PrimaryCTAButton>
                </WrapperButton>
              )}
            </FlexBetween>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {terms.map((term, index) => (
              <div key={index}>
                <Title>{term.snumber}</Title>
                <Para style={StyledPara}>{term.text}</Para>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </CardWrapper>
  );
};

export default Index;

export const CardWrapper = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  width: 618px;
  padding: 0 44px 30px 19px;
  border-radius: 11px;
  border: solid 0.5px #e4e5ef;
  .heading {
    width: 100%;
  }
`;
export const CardHeader = styled.h2`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
`;

export const Title = styled.h3`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  font-weight: 600;
  height: 19px;
`;

export const Para = styled.p`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  font-weight: 300;
  line-height: 1.42;
  margin-bottom: 10px;
  margin-top: 5px;
  /* height:64px; */
`;
const WrapperButton = styled.div`
  width: 146px;
  margin-right: 15px;
`;
