import React, { useState, useCallback } from "react";

export const AlertContext = React.createContext();

export const closedAlertState = {
  heading: "",
  desc: "",
  primaryBtnText: "Submit",
  secondaryBtnText: "Close",
  clickSecondarybtn: () => {},
  clickPrimaryBtn: () => {},
  isOpen: false,
  onDialogClose: () => {},
};

function AlertProvider({ children }) {
  const [alert, setAlert] = useState(closedAlertState);
  const resetState = () => setAlert(closedAlertState);
  const setState = (props) => setAlert((a) => ({ ...a, ...props }));
  const hideAlert = useCallback(resetState, []);
  const showAlert = useCallback(setState, []);

  const value = React.useMemo(() => ({ alert, hideAlert, showAlert }), [
    alert,
    hideAlert,
    showAlert,
  ]);

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
}

export default AlertProvider;
