import { useContext } from "react";
import { DictationContext } from "../providers/OrderDictationProvider";

export default function useDictation() {
  const context = useContext(DictationContext);
  if (context === undefined) {
    throw new Error("useCartCount must be used within a CartCountProvider");
  }
  return context;
}
