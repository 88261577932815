import React from "react";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
} from "../../../styles/component/style";
import { PrimaryCTAButton } from "../../common/Buttons";
import { Form, Formik } from "formik";
import { LoginEmailValidationSchema } from "../../../helpers/validationSchema";
import FormField from "../../common/FormField/FormField";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { navigate } from "@reach/router";
import { getErrorMessage } from "../../../helpers/functions";
import useUser from "../../../hooks/useUser";
import useLoader from "../../../hooks/useLoader";

const Login = ({
  heading = "Login",
  inputs = [],
  submitLabel = "Login",
  disableSubmit = false,
  location,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setToken } = useUser();
  const { state } = location;
  const { setLoader } = useLoader();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true });
      const response = await AuthService.login(values);
      if (response.token) {
        localStorage.setItem("auth_token", response.token);
        setToken(response.token);
        enqueueSnackbar("Login successful", {
          variant: "success",
        });
        navigate("/dashboard");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Formik
      initialValues={{
        email: state?.payload?.email || "",
        password: state?.payload?.password || "",
      }}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={LoginEmailValidationSchema}
      validateOnBlur
      validateOnChange
    >
      {({
        values,
        handleChange,
        errors,
        handleSubmit,
        touched,
        handleBlur,
        setFieldValue,
      }) => (
        <Form className="w100p" onSubmit={handleSubmit}>
          <CardWrapper>
            <HeadingContainer>
              <Header3>{heading}</Header3>
            </HeadingContainer>
            <ContentContainer>
              {inputs.map((input, index) => {
                return (
                  <FormField
                    key={index}
                    {...{
                      touched,
                      errors,
                      input,
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                );
              })}
            </ContentContainer>

            <div className="pt20 pb10">
              <PrimaryCTAButton
                type="submit"
                onClick={handleSubmit}
                disabled={disableSubmit}
              >
                {submitLabel}
              </PrimaryCTAButton>
            </div>
          </CardWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
