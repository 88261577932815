import React from "react";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import { Formik } from "formik";
import FormField from "../../../common/FormField/FormField";
import { PrimaryCTAButton } from "../../../common/Buttons";

const inputs = [
  {
    type: "number",
    name: "counterClaimDeadline",
    label: "Counter Claim Deadline (Days)",
  },
];

const CounterClaim = ({ systemConfig, handleSystemConfigChange }) => {
  return (
    <DrawerContainer role="presentation">
      <Container>
        <FormContainer>
          <Formik
            initialValues={{
              counterClaimDeadline: systemConfig?.counterClaimDeadline || "",
            }}
            onSubmit={({ counterClaimDeadline }) =>
              handleSystemConfigChange({
                ...systemConfig,
                counterClaimDeadline,
              })
            }
            validateOnBlur
            enableReinitialize
            validateOnChange
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              isValid,
            }) => (
              <StyledForm>
                {inputs?.map((input, key) => (
                  <FormField
                    white
                    {...{
                      key,
                      input,
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                ))}
                <ButtonContainer style={{ marginBottom: 13 }}>
                  <PrimaryCTAButton
                    type="submit"
                    onClick={handleSubmit}
                    style={{ width: "30%", height: 35 }}
                  >
                    Update
                  </PrimaryCTAButton>
                </ButtonContainer>
              </StyledForm>
            )}
          </Formik>
        </FormContainer>
      </Container>
    </DrawerContainer>
  );
};

export default CounterClaim;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;

  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 450px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 18px;
  justify-content: flex-end;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const FormContainer = styled.div`
  padding: 15px;
  .link-container {
    margin: 12px;
  }
  .date-time {
    display: flex;
    .date {
      margin: 12px;
      width: 100%;
    }
    .time {
      margin: 12px;
      width: 100%;
    }
  }
`;
