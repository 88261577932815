import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

export const CalendarContext = React.createContext();

function CalendarProvider({ children }) {
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [filteredEvents, setFilterEvents] = useState([]);
  const [showEventModal, setShowEventModal] = useState({ state: false });

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setMonthIndex(smallCalendarMonth);
    }
  }, [smallCalendarMonth]);

  const value = React.useMemo(() => {
    return {
      monthIndex,
      setMonthIndex,
      smallCalendarMonth,
      setSmallCalendarMonth,
      daySelected,
      setDaySelected,
      filteredEvents,
      setFilterEvents,
      showEventModal,
      setShowEventModal,
    };
  }, [
    monthIndex,
    setMonthIndex,
    smallCalendarMonth,
    setSmallCalendarMonth,
    daySelected,
    setDaySelected,
    filteredEvents,
    setFilterEvents,
    showEventModal,
    setShowEventModal,
  ]);

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
}

export default CalendarProvider;
