import React from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { PrimaryCTAButton, SecondaryCTAButton } from "../../../common/Buttons";
import { Formik } from "formik";
import { CustomFileUpload } from "../../../common/FormInputs";
import { FormLabel } from "@material-ui/core";
import useLoader from "../../../../hooks/useLoader";
import AWSService from "../../../../services/AWSService";
import { getErrorMessage, getFileName } from "../../../../helpers/functions";

export default function UpdateArbitrationTemplates({
  modal,
  setModal,
  systemConfig,
  handleSystemConfigChange = () => {},
}) {
  const { setLoader } = useLoader();

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    try {
      setLoader({ state: true, message: `Uploading file ${file.name} ...` });
      const payload = {
        key: file.name,
      };
      const s3Response = await AWSService.getS3URL(payload);
      if (s3Response.url) {
        const res = await AWSService.uploadToS3(s3Response.url, file);
        setFieldValue(field, res);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      setFieldError(field, message);
    } finally {
      setLoader({ state: false });
    }
  }

  const addMoreDocs = (values, setFieldValue) => {
    setFieldValue(`arbitrationTemplateUrl`, [...values.arbitrationTemplateUrl, ""]);
  };

  const handleDeleteDocs = (index, arbitrationTemplateUrl, setFieldValue) => {
    if (arbitrationTemplateUrl?.length) {
      const filtered = arbitrationTemplateUrl.filter((v, idx) => idx !== index);
      setFieldValue("arbitrationTemplateUrl", filtered);
    }
  };

  return (
    <Drawer anchor={"right"} open={modal} onClose={() => setModal(false)}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Update Arbitration Template</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <EditProfileContainer>
          <Formik
            initialValues={{
              arbitrationTemplateUrl: systemConfig.arbitrationTemplateUrl || [],
            }}
            onSubmit={({ arbitrationTemplateUrl }) => {
              handleSystemConfigChange({ ...systemConfig, arbitrationTemplateUrl }, () =>
                setModal(false)
              )
            }}
            validateOnBlur
            enableReinitialize
            validateOnChange
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              isValid,
            }) => (
              <StyledForm>
                <StyledFormElementsWrapper style={{ margin: "40px 0 74px" }}>
                  
                  {values.arbitrationTemplateUrl ? values.arbitrationTemplateUrl.map((arbitrationTemplateUrl, index) => (
                    <FileUploadBox>
                    <FormLabel 
                    style={{
                      fontFamily: theme.fonts.primaryFontSemiBold,
                      fontSize: 16,
                      marginTop:"10px",
                      color: COLORS.INPUT_LABEL,
                    }}
                    >Files {index+1} (.pdf or .docx)</FormLabel>
                    <FileUploadContainer>
                      {values.arbitrationTemplateUrl.length > 1 && (
                        <DeleteIcon
                          
                          onClick={() => {
                            handleDeleteDocs(
                              index,
                              values.arbitrationTemplateUrl,
                              setFieldValue
                            );
                          }}
                          key={index}
                        > <img src={require("../../../../assets/images/removeIcon.svg")} alt="delete"></img></DeleteIcon>
                      )}
                      <CustomFileUpload
                        style={{ width: "100%", marginTop: 6 }}
                        dark
                        file={{ name: getFileName(arbitrationTemplateUrl) }}
                        fileUrl={arbitrationTemplateUrl}
                        setFile={(file) =>
                          s3Upload(
                            file,
                            `arbitrationTemplateUrl[${index}]`,
                            setFieldValue,
                            setFieldError
                          )
                        }
                        accept=".pdf, .docx" 
                      />
                    </FileUploadContainer>
                    </FileUploadBox>
                  )):''}

                  <SecondaryCTAButton
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                      addMoreDocs(values, setFieldValue);
                    }}
                  >
                    + More Arbitration Template Files
                  </SecondaryCTAButton>
                </StyledFormElementsWrapper>

                <ButtonContainer style={{ marginBottom: 13 }}>
                  <PrimaryCTAButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isValid || values.arbitrationTemplateUrl.includes("")}
                    style={{ width: 413, margin: "auto" }}
                  >
                    Update Arbitration Template Files
                  </PrimaryCTAButton>
                </ButtonContainer>
              </StyledForm>
            )}
          </Formik>
        </EditProfileContainer>
      </DrawerContainer>
    </Drawer>
  );
}
const DrawerContainer = styled.div`
  width: 618px;
`;
const HeadingContainer = styled.div`
  padding-top: 28px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;
const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
  span {
    font-family: ${theme.fonts.primaryFontSemiBold};
    text-decoration: underline;
    margin-left: 23px;
    font-size: 12px;
    color: ${COLORS.BTN_GREEN};
    user-select: none;
  }
`;
const EditProfileContainer = styled.div`
  margin: 0 30px 0 30px;
`;
const StyledForm = styled.form`
  width: 100%;
`;
const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
`;

const DeleteIcon = styled.div`
  margin: 20px 5px 0px;
  cursor: pointer;
`;
const FileUploadContainer = styled.div `
display:flex;
`;
const FileUploadBox=styled.div `
display:flex;
flex-direction:column;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;