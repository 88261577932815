import React from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { PrimaryCTAButton } from "../../../common/Buttons";
import { Formik } from "formik";
import FormField from "../../../common/FormField/FormField";
import { LoginMobileValidationSchema } from "../../../../helpers/validationSchema";

export default function UpdatePhone({
  modal,
  setModal,
  systemConfig,
  handleSystemConfigChange = () => {},
}) {
  return (
    <Drawer anchor={"right"} open={modal} onClose={() => setModal(false)}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Update Call Centre Number</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <EditProfileContainer>
          <Formik
            initialValues={{
              mobile: systemConfig?.mobile || "",
            }}
            onSubmit={({ mobile }) =>
              handleSystemConfigChange({ ...systemConfig, mobile }, () =>
                setModal(false)
              )
            }
            validationSchema={LoginMobileValidationSchema}
            validateOnBlur
            enableReinitialize
            validateOnChange
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              isValid,
            }) => (
              <StyledForm>
                <StyledFormElementsWrapper style={{ margin: "80px 0 74px" }}>
                  <FormField
                    input={{
                      type: "text",
                      name: "mobile",
                      label: "NEW CAll CENTER NUMBER",
                    }}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </StyledFormElementsWrapper>

                <ButtonContainer style={{ marginBottom: 13 }}>
                  <PrimaryCTAButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isValid}
                    style={{ width: 413, margin: "auto" }}
                  >
                    Update Number
                  </PrimaryCTAButton>
                </ButtonContainer>
              </StyledForm>
            )}
          </Formik>
        </EditProfileContainer>
      </DrawerContainer>
    </Drawer>
  );
}
const DrawerContainer = styled.div`
  width: 618px;
`;
const HeadingContainer = styled.div`
  padding-top: 28px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;
const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
  span {
    font-family: ${theme.fonts.primaryFontSemiBold};
    text-decoration: underline;
    margin-left: 23px;
    font-size: 12px;
    color: ${COLORS.BTN_GREEN};
    user-select: none;
  }
`;
const EditProfileContainer = styled.div`
  margin: 0 30px 0 30px;
`;
const StyledForm = styled.form`
  width: 100%;
`;
const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;