import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const DrawerContainer = styled.div`
  max-width: 516px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

export const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

export const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const PickerIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 34px;
  height: 34px;
  object-fit: contain;
`;

export const ProfilePic = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 2px;
  position: relative;
`;
