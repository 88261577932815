import { Modal } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

const Index = ({ view, setView }) => {
  return (
    <Modal open={view?.state} onClose={() => setView({ state: false })}>
      <DrawerContainer>
        <HeadingContainer>
          <Heading>{"Extract"}</Heading>
          <CloseModal
            onClick={() => setView({ state: false })}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <div>
            <Label>{"Keywords"}</Label>
            <FormLabel style={{ marginBottom: "5px" }}>
              {view?.keywords ? view?.keywords.join(", ") : ""}
            </FormLabel>
            <Label>{"Summary"}</Label>
            <FormLabel>
              {view?.documentSummary ? view?.documentSummary : ""}
            </FormLabel>
          </div>
        </AgentFormContainer>
      </DrawerContainer>
    </Modal>
  );
};

export default Index;

const DrawerContainer = styled.div`
  width: 60%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 47px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  overflow: auto;
  max-height: 350px;
`;

const FormLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
  margin-right: 8px;
`;

export const Label = styled.span`
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 14px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
  text-transform: capitalize;
`;
