import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Container,
  TableWrapper,
  HyperLink,
  StyledAmount,
  TopCenter,
  Center,
} from "../PaymentHistory/Styles";
import _ from "lodash";
import queryString from "query-string";
import CustomTable from "../../common/CustomTable/CustomTable";
import CaseService from "../../../services/CaseService";
import moment from "moment";
import {
  getErrorMessage,
  getFileName,
  numberFormat,
} from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import labels from "../../../helpers/labels.json";
import { navigate } from "@reach/router";

async function getPaymentRefunds(query = "") {
  try {
    const response = await CaseService.getRefundHistory(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}
const PaymentRefund = () => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const placeholderText = "Search Refund";
  const { enqueueSnackbar } = useSnackbar();
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    if (triggerRefresh) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
      setTriggerRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRefresh]);

  const columns = [
    {
      field: "caseId",
      title: "Case ID",
      sorting: true,
      render: (rowData) =>
        rowData?.caseId === "Multiple" ? (
          <HyperLink>
            <TopCenter>{rowData?.caseId}</TopCenter>
          </HyperLink>
        ) : (
          <HyperLink
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(
                `/dashboard/cases/${rowData?.caseId}?caseType=${rowData?.case?.resolutionKind}`
              )
            }
          >
            <TopCenter>{rowData?.caseId}</TopCenter>
          </HyperLink>
        ),
      headerStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
    },
    {
      field: "id",
      title: labels["table.order_id"],
      tooltip: "Payment order identifier",
      sorting: true,
      render: (rowData) => (
        <HyperLink>
          <TopCenter>{rowData?.id}</TopCenter>
        </HyperLink>
      ),
      headerStyle: {
        maxWidth: 110,
        width: 110,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 110,
        width: 110,
        padding: `12px 16px`,
      },
    },
    {
      field: "id",
      title: labels["table.refund_invoice_id"],
      tooltip: "Invoice identifier",
      sorting: true,
      render: (rowData) => (
        <HyperLink
          style={{
            textDecoration: "none",
            cursor: "inherit",
          }}
        >
          {rowData?.id}
        </HyperLink>
      ),
    },
    {
      field: "parties.name",
      title: labels["table.party_name"],
      sorting: false,
      render: (rowData) => rowData?.party?.name,
    },
    {
      field: "amount",
      title: labels["table.total_amount"],
      sorting: false,
      render: (rowData) => (
        <StyledAmount>
          {numberFormat(
            parseFloat(rowData?.cartItem?.amount?.toFixed(2)),
            rowData?.cartItem.fee.unit
          )}
        </StyledAmount>
      ),
    },
    {
      field: "amount",
      title: labels["table.refund_amount"],
      tooltip: "The amount approved for refund",
      sorting: false,
      render: (rowData) => (
        <StyledAmount>
          {/* {!_.isEmpty(rowData.refundInfo)
            ? Number(`${rowData?.refundInfo?.totalRefund}`).toFixed(2)
            : Number(`${rowData?.allowedRefund.refundAmount}`).toFixed(2)} */}
          {!_.isEmpty(rowData)
            ? numberFormat(
                parseFloat(`${rowData?.refundInfo?.totalRefund}`).toFixed(2),
                rowData?.cartItem.fee.unit
              )
            : numberFormat(
                parseFloat(`${rowData?.allowedRefund.refundAmount}`).toFixed(2),
                rowData?.cartItem.fee.unit
              )}
        </StyledAmount>
      ),
    },
    {
      field: "created_at",
      title: labels["table.date"],
      sorting: false,
      render: (rowData) => (
        <>
          {moment(rowData?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          )}
        </>
      ),
    },
    {
      field: "invoiceUrl",
      title: labels["table.download"],
      sorting: false,
      render: (rowData) =>
        rowData?.refundReceiptUrl ? (
          <HyperLink
            style={{
              wordBreak: "break-word",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => window.open(rowData?.refundReceiptUrl)}
          >
            {rowData?.refundReceiptUrl
              ? getFileName(rowData?.refundReceiptUrl)?.split(".pdf")[0]
              : "Download"}
          </HyperLink>
        ) : (
          <Center>-</Center>
        ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        kind: "refund",
      };
      if (query.search) {
        params.query = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";

      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getPaymentRefunds(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <Fragment>
      <Container className="CreateCase" style={{ overflow: "hidden" }}>
        <TableWrapper>
          <CustomTable
            hidePagination={state.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle={labels.payment_refund}
            singularTitle=""
            {...{
              columns,
              MTRef,
              placeholderText,
              data,
            }}
            //noToolbar
            hideFilterBar
            state={state}
          />
        </TableWrapper>
      </Container>
    </Fragment>
  );
};

export default PaymentRefund;
