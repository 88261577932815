import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles({
  list: {
    width: "800px",
  },
});

const CustomDrawer = ({
  state,
  setState,
  children = "drawer content",
  doClose = true,
}) => {
  const classes = useStyles();

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={state}
          onClose={() => (doClose ? setState(false) : {})}
          disableRestoreFocus={true}
        >
          <div className={classes.list} role="presentation">
            {children}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default CustomDrawer;
