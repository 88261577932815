import { Modal } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { Formik } from "formik";
import FormField from "../../../common/FormField/FormField";
import { MobileOtpConfig } from "../../../../helpers/validationSchema";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";

const inputs = [
  {
    type: "number",
    name: "noOfOtpAttempts",
    label: "No Of Otp Attempts",
    required: true,
  },
  {
    type: "number",
    name: "timeIntervalForOtpAttempts",
    label: "Time Interval For Otp Attempts(hours)",
    required: true,
  },
];

const Index = ({ systemConfig, handleSystemConfigChange, modal, setModal }) => {
  return (
    <>
      <Modal open={modal} onClose={() => setModal(false)}>
        <DrawerContainer role="presentation">
          <Container>
            <HeadingContainer>
              <Heading>{"Otp Login Configuration"}</Heading>
              <CloseModal
                onClick={() => setModal(false)}
                src={require("../../../../assets/images/closeModal.svg")}
              />
            </HeadingContainer>
            <FormContainer>
              <Formik
                initialValues={{
                  noOfOtpAttempts: systemConfig?.noOfOtpAttempts || "",
                  timeIntervalForOtpAttempts:
                    systemConfig?.timeIntervalForOtpAttempts || "",
                }}
                onSubmit={({ noOfOtpAttempts, timeIntervalForOtpAttempts }) =>
                  handleSystemConfigChange(
                    {
                      ...systemConfig,
                      noOfOtpAttempts,
                      timeIntervalForOtpAttempts,
                    },
                    () => setModal(false)
                  )
                }
                validationSchema={MobileOtpConfig}
                validateOnBlur
                enableReinitialize
                validateOnChange
              >
                {({
                  values,
                  handleChange,
                  errors,
                  handleSubmit,
                  touched,
                  handleBlur,
                  setFieldValue,
                  isValid,
                }) => (
                  <StyledForm>
                    {inputs?.map((input, key) => (
                      <FormField
                        white
                        {...{
                          key,
                          input,
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                        }}
                      />
                    ))}
                    <ButtonContainer style={{ marginBottom: 13 }}>
                      <PrimaryCTAButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!isValid}
                        style={{ width: "45%", margin: "auto" }}
                      >
                        Update
                      </PrimaryCTAButton>
                      <PrimaryOutlinedCTAButton
                        type="submit"
                        onClick={() => setModal(false)}
                        disabled={!isValid}
                        style={{ width: "45%", margin: "auto" }}
                      >
                        Cancel
                      </PrimaryOutlinedCTAButton>
                    </ButtonContainer>
                  </StyledForm>
                )}
              </Formik>
            </FormContainer>
          </Container>
        </DrawerContainer>
      </Modal>
    </>
  );
};

export default Index;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 450px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const StyledForm = styled.form`
  width: 100%;
`;

const FormContainer = styled.div`
  padding: 15px;
  .link-container {
    margin: 12px;
  }
  .date-time {
    display: flex;
    .date {
      margin: 12px;
      width: 100%;
    }
    .time {
      margin: 12px;
      width: 100%;
    }
  }
`;
