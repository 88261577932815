/**
 * @param {*} seconds
 * @param {*} fps
 */
const normalisePlayerTime = function (seconds, fps) {
  return Number((1.0 / fps * Math.floor(Number((fps * seconds).toPrecision(12)))).toFixed(2));
};

/*
* @param {*} seconds
* @param {*} fps
*/
const secondsToTimecode = function (seconds, framePerSeconds) {
  // written for PAL non-drop timecode
  let fps = 25;
  if (framePerSeconds !== undefined) {
    fps = framePerSeconds;
  }

  const normalisedSeconds = normalisePlayerTime(seconds, fps);

  const wholeSeconds = Math.floor(normalisedSeconds);
  const frames = ((normalisedSeconds - wholeSeconds) * fps).toFixed(2);

  // prepends zero - example pads 3 to 03
  function _padZero(n) {
    if (n < 10) return `0${parseInt(n)}`;

    return parseInt(n);
  }

  return `${_padZero((wholeSeconds / 60 / 60) % 60)
    }:${_padZero((wholeSeconds / 60) % 60)
    }:${_padZero(wholeSeconds % 60)
    }:${_padZero(frames)}`;
};

export default secondsToTimecode;
