import { useLocation } from "@reach/router";
import React, { useEffect, useState } from "react";
import useCartCount from "../../../hooks/useCartCount";
import CasesTable from "../../common/CasesTable";
import _ from "lodash";
import ActionBar from "../../common/ActionBar";
import labels from "../../../helpers/labels.json";
import AssignCaseManagerBulkCase from "../../pages/AssignCaseManagerBulkCase/index";

const CaseListing = () => {
  const location = useLocation();
  const { stats } = useCartCount();
  const [caseType, setCaseType] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const [modal, setModal] = useState({ state: false });

  useEffect(() => {
    if (!_.isEmpty(stats)) {
      if (searchParams.get("caseType")) {
        setCaseType(searchParams.get("caseType"));
      } else {
        setCaseType("arbitration");
      }
    }
  }, [searchParams, stats]);

  const actions = [
    {
      text: "Assign IIAC Counsel",
      props: {
        onClick: () => setModal({ state: true }),
      },
    },
  ];

  return (
    <div>
      <ActionBar
        actions={actions}
        breadcrumbs={[_.startCase(labels.cases), _.startCase(caseType)]}
      />
      {caseType && (
        <CasesTable
          caseType={caseType}
          status={searchParams.get("status")}
          currentCaseType={caseType}
        />
      )}
      <AssignCaseManagerBulkCase {...{ modal, setModal }} />
    </div>
  );
};

export default CaseListing;
