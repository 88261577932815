export const states = [
  { label: "Andaman and Nicobar", value: "AN", pinStart: [74] },
  { label: "Andhra Pradesh", value: "AP", pinStart: [50, 51, 52, 53] },
  { label: "Arunachal Pradesh", value: "AR", pinStart: [79] },
  { label: "Assam", value: "AS", pinStart: [78] },
  { label: "Bihar", value: "BR", pinStart: [80, 81, 82, 83, 84, 85] },
  { label: "Chandigarh", value: "CG", pinStart: [16] },
  { label: "Chhattisgarh", value: "CH", pinStart: [45, 46, 47, 48, 49] },
  { label: "Dadra and Nagar Haveli", value: "DH", pinStart: [39] },
  { label: "Daman and Diu", value: "DD", pinStart: [36, 39] },
  { label: "Delhi", value: "DL", pinStart: [11] },
  { label: "Goa", value: "GA", pinStart: [40] },
  { label: "Gujarat", value: "GJ", pinStart: [36, 37, 38, 39] },
  { label: "Haryana", value: "HR", pinStart: [13] },
  { label: "Himachal Pradesh", value: "HP", pinStart: [17] },
  { label: "Jammu and Kashmir", value: "JK", pinStart: [18, 19] },
  { label: "Jharkhand", value: "JH", pinStart: [80, 81, 82, 83, 84, 85] },
  { label: "Karnataka", value: "KA", pinStart: [56, 57, 58, 59] },
  { label: "Kerala", value: "KL", pinStart: [67, 68, 69] },
  { label: "Ladakh", value: "LA", pinStart: [18, 19] },
  { label: "Lakshadweep", value: "LD", pinStart: [68] },
  { label: "Madhya Pradesh", value: "MP", pinStart: [45, 46, 47, 48, 49] },
  { label: "Maharashtra", value: "MH", pinStart: [40, 41, 42, 43, 44] },
  { label: "Manipur", value: "MN", pinStart: [79] },
  { label: "Meghalaya", value: "ML", pinStart: [79] },
  { label: "Mizoram", value: "MZ", pinStart: [79] },
  { label: "Nagaland", value: "NL", pinStart: [79] },
  { label: "Odisha", value: "OR", pinStart: [75, 76, 77] },
  { label: "Puducherry", value: "PY", pinStart: [60] },
  { label: "Punjab", value: "PB", pinStart: [14, 15, 16] },
  { label: "Rajasthan", value: "RJ", pinStart: [30, 31, 32, 33, 34] },
  { label: "Sikkim", value: "SK", pinStart: [73] },
  { label: "Tamil Nadu", value: "TN", pinStart: [60, 61, 62, 63, 64] },
  { label: "Telangana", value: "TS", pinStart: [50, 51, 52, 53] },
  { label: "Tripura", value: "TR", pinStart: [79] },
  {
    label: "Uttar Pradesh",
    value: "UP",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "Uttarakhand",
    value: "UK",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "West Bengal",
    value: "WB",
    pinStart: [70, 71, 72, 73, 74],
  },
];

/**
 * This the Case Life cycle for CLaimant
 */
export const AWAITING_RESPONDANT = "awaitingRespondant";
export const RESPONDANT_ONBOARDED = "respondantOnboarded";
export const NEGOTIATION_ONGOING = "negotiationOngoing";
export const NEGOTIATION_REACHED = "negotiationReached";
export const NEGOTIATION_QUIT_BY_CLAIMENT = "quitByClaimant";
export const NEGOTIATION_QUIT_BY_RESPONDENT = "quitByRespondent";
export const NEGOTIATION_QUIT_BY_CASEMANAGER = "closedByCaseManager";
export const CLAIMENT_PAID_NEGOTIATION = "claimantPaidNegotiationSuccessFee";
export const RESPONDENT_PAID_NEGOTIATION =
  "respondentPaidNegotiationSuccessFee";
export const BOTH_PAID_NEGOTIATION = "bothPaidNegotiationSuccessFee";
export const AWAITING_RESPONDANT_QUIT = "caseclosedByCaseManager";

export const CLAIMENT_STR = "claimant";

/* addition Case Lifecycle status change */

export const ARBITRATION_REFERENCE = "arbitrationReference";
export const NOTICE_TO_ARBITRATE = "noticeToArbitrate";
export const APPOINTMENT_OF_ARBITRATOR = "appointmentOfArbitrator";
export const ACCEPTANCE_BY_ARBITRATOR = "acceptanceByArbitrator";
export const FIRST_NOTICE_FIRST_HEARING_INTIMATION = "firstHearingIntimation";
export const FILING_STATEMENT_OF_CLAIM = "filingStatementofClaim";
export const FILING_OF_SECTION_SEVENTEEN = "filingofSection17";
export const SECTION_SEVENTEEN_ORDER_PASSED = "section17OrderPassed";
export const FILING_OF_STATEMENT_OF_DEFENCE = "filingofStatementofDefence";
export const REJOINDER_FROM_CLAIMNT = "rejoinderfromClaimant";
export const SURREJOINDER_FROM_RESPONDENT = "surrejoinderFromRespondent";
export const SECOND_NOTICE_MOM = "2ndNoticeMOM";
export const CROSSEXAMINATION_CLAIMANT_WITNESS =
  "crossExaminationClaimantWitness";
export const CROSSEXAMINATION_RESPONDENT_WITNESS =
  "crossExaminationRespondentWitness";
export const ARGUMENT = "arguments";
export const RESERVED_FOR_AWARD = "reservedForAward";

/**This variable is use for CLaimant life cycle */
export const CLAIMANT_LIFE_CYCLE = [
  {
    label: "Awaiting Respondent onboarding",
    key: AWAITING_RESPONDANT,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Awaiting Respondent onboarding",
    key: AWAITING_RESPONDANT_QUIT,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Case Accepted/Preparation",
    key: RESPONDANT_ONBOARDED,
    index: 2,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_CLAIMENT,
    index: 3,
    enableSelection: false,
    cta: [
      {
        type: "REOPEN_CASE",
        label: "Reopen Case",
        displayBtn: true,
        danger: false,
      },
    ],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_RESPONDENT,
    index: 3,
    enableSelection: false,
    cta: [
      {
        type: "REOPEN_CASE",
        label: "Reopen Case",
        displayBtn: true,
        danger: false,
      },
    ],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_CASEMANAGER,
    index: 3,
    enableSelection: false,
    cta: [
      {
        type: "REOPEN_CASE",
        label: "Reopen Case",
        displayBtn: true,
        danger: false,
      },
    ],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_ONGOING,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Concluded",
    key: NEGOTIATION_REACHED,
    index: 4,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: CLAIMENT_PAID_NEGOTIATION,
    index: 5,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: BOTH_PAID_NEGOTIATION,
    index: 5,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: RESPONDENT_PAID_NEGOTIATION,
    index: 5,
    enableSelection: false,
    cta: [],
  },

  /* Case Lifecycle status */

  {
    label: "Arbitration Reference",
    key: ARBITRATION_REFERENCE,
    index: 2,
    enableSelection: false,
    status: "Arbitration Reference",
    cta: [],
  },
  {
    label: "Notice To Arbitrate",
    key: NOTICE_TO_ARBITRATE,
    index: 2,
    enableSelection: false,
    status: "Notice To Arbitrate",
    cta: [],
  },
  {
    label: "Appointment To Arbitrator",
    key: APPOINTMENT_OF_ARBITRATOR,
    index: 2,
    enableSelection: false,
    status: "Appointment To Arbitrator",
    cta: [],
  },
  {
    label: "Acceptance by Arbitrator",
    key: ACCEPTANCE_BY_ARBITRATOR,
    index: 2,
    enableSelection: false,
    status: "Acceptance by Arbitrator	",
    cta: [],
  },
  {
    label: "1st Notice (First Hearing Intimation)",
    key: FIRST_NOTICE_FIRST_HEARING_INTIMATION,
    index: 4,
    enableSelection: false,
    status: "1st Notice (First Hearing Intimation)",
    cta: [],
  },
  {
    label: "Filing Statement of Claim",
    key: FILING_STATEMENT_OF_CLAIM,
    index: 4,
    enableSelection: false,
    status: "Filing Statement of Claim",
    cta: [],
  },
  {
    label: "Filing of Section 17",
    key: FILING_OF_SECTION_SEVENTEEN,
    index: 4,
    enableSelection: false,
    status: "Filing of Section 17",
    cta: [],
  },
  {
    label: "Section 17 Order Passed",
    key: SECTION_SEVENTEEN_ORDER_PASSED,
    index: 4,
    enableSelection: false,
    status: "Section 17 Order Passed",
    cta: [],
  },
  {
    label: "Filing of Statement of Defence",
    key: FILING_OF_STATEMENT_OF_DEFENCE,
    index: 4,
    enableSelection: false,
    status: "Filing of Statement of Defence",
    cta: [],
  },
  {
    label: "Rejoinder from Claimant",
    key: REJOINDER_FROM_CLAIMNT,
    index: 4,
    enableSelection: false,
    status: "Rejoinder from Claimant",
    cta: [],
  },
  {
    label: "Surrejoinder from Respondent",
    key: SURREJOINDER_FROM_RESPONDENT,
    index: 4,
    enableSelection: false,
    status: "Surrejoinder from Respondent",
    cta: [],
  },
  {
    label: "2nd Notice (MOM)",
    key: SECOND_NOTICE_MOM,
    index: 4,
    enableSelection: false,
    status: "2nd Notice (MOM)",
    cta: [],
  },
  {
    label: "Cross Examination - Claimant Witness",
    key: CROSSEXAMINATION_CLAIMANT_WITNESS,
    index: 4,
    enableSelection: false,
    status: "Cross Examination - Claimant Witness",
    cta: [],
  },
  {
    label: "Cross Examination - Respondent Witness",
    key: CROSSEXAMINATION_RESPONDENT_WITNESS,
    index: 4,
    enableSelection: false,
    status: "Cross Examination - Respondent Witness",
    cta: [],
  },
  {
    label: "Arguments",
    key: ARGUMENT,
    index: 4,
    enableSelection: false,
    status: "Arguments",
    cta: [],
  },
  {
    label: "Reserved for Award",
    key: RESERVED_FOR_AWARD,
    index: 4,
    enableSelection: false,
    status: "Reserved for Award",
    cta: [],
  },
];

/**
 * This is the Case Life cycle for Respontent
 */
export const RESPONTANT_LIFE_CYCLE = [
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_ONGOING,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_RESPONDENT,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_CASEMANAGER,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_CLAIMENT,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Concluded",
    key: NEGOTIATION_REACHED,
    index: 2,
    enableSelection: false,
    cta: [
      {
        type: "MOVE_TO_AGREEMENT",
        label: "Go to Agreement",
        displayBtn: true,
      },
    ],
  },
  {
    label: "Agreement",
    key: CLAIMENT_PAID_NEGOTIATION,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: BOTH_PAID_NEGOTIATION,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: RESPONDENT_PAID_NEGOTIATION,
    index: 3,
    enableSelection: false,
    cta: [],
  },
];

export const SplitAudioMinutes = [
  { label: "Select Duration", value: "", disabled: true },
  { label: "15 Minutes", value: "15" },
  { label: "30 Minutes", value: "30" },
  { label: "45 Minutes", value: "45" },
  { label: "60 Minutes", value: "60" },
  { label: "75 Minutes", value: "75" },
  { label: "90 Minutes", value: "90" },
  { label: "120 Minutes", value: "120" },
];

export const TimeBuffer = [
  { label: "Select Buffer", value: "", disabled: true },
  { label: "0", value: 0 },
  {
    label: "200 ms",
    value: "200",
  },
  { label: "300 ms", value: "300" },
  { label: "500 ms", value: "500" },
];

export const SettingsDuration = [
  { label: "Select Duration", value: "", disabled: true },
  {
    label: "2 Seconds",
    value: "0.03333333333333333333",
  },
  {
    label: "5 Seconds",
    value: "0.08333333333333333",
  },
  {
    label: "10 Seconds",
    value: "0.17",
  },
  {
    label: "12 Seconds",
    value: "0.2",
  },
  {
    label: "15 Seconds",
    value: "0.25",
  },
  {
    label: "30 Seconds",
    value: "0.5",
  },
  {
    label: "1 Minute",
    value: "1",
  },
  {
    label: "2 Minutes",
    value: "2",
  },
  { label: "3 Minutes", value: "3" },
  { label: "5 Minutes", value: "5" },
];

export const TranscriptBuffer = [
  { label: "Select Transcript Buffer", value: "", disabled: true },
  {
    label: "10 Seconds",
    value: "10",
  },
  { label: "20 Seconds", value: "20" },
  { label: "30 Seconds", value: "30" },
];

export const ModelType = [
  { label: "Select Model Type", value: "", disabled: true },
  { label: "Tiny", value: "tiny" },
  { label: "Tiny_EN", value: "tiny.en" },
  {
    label: "Base",
    value: "base",
  },
  {
    label: "Base_EN",
    value: "base.en",
  },
  { label: "Small", value: "small" },
  { label: "Small_EN", value: "small.en" },
  { label: "Medium", value: "medium" },
  { label: "Medium_EN", value: "medium.en" },
  { label: "Large", value: "large" },
];

export const TextType = [
  { label: "Select Type", value: "", disabled: true },
  {
    label: "Question",
    value: "Question",
  },
  { label: "Answer", value: "Answer" },
  { label: "Statement", value: "Statement" },
  { label: "Order", value: "Order" },
];

export const deepgram_modal_type = [
  { label: "Nova-2", value: "nova-2" },
  { label: "Nova-2-General", value: "nova-2-general" },
  { label: "Nova-2-Meeting", value: "nova-2-meeting" },
  { label: "Nova-2-Phonecall", value: "nova-2-phonecall" },
  { label: "Nova-2-Finance", value: "nova-2-finance" },
  { label: "Nova-2-Conversationalai", value: "nova-2-conversationalai" },
  { label: "Nova-2-Voicemail", value: "nova-2-voicemail" },
  { label: "Nova-2-Video", value: "nova-2-video" },
  { label: "Nova-2-Medical", value: "nova-2-medical" },
  { label: "Nova-2-Drivethru", value: "nova-2-drivethru" },
  { label: "Nova-2-Automotive", value: "nova-2-automotive" },
  { label: "Nova", value: "nova" },
  { label: "Nova-General", value: "nova-general" },
  { label: "Nova-Phonecall", value: "nova-phonecall" },
  { label: "Nova-Medical", value: "nova-medical" },
  {
    label: "Enhanced or Enhanced-General",
    value: "enhanced or enhanced-general",
  },
  { label: "Enhanced-Meeting", value: "enhanced-meeting" },
  { label: "Enhanced-Phonecall", value: "enhanced-phonecall" },
  { label: "Enhanced-Finance", value: "enhanced-finance" },
  { label: "Base", value: "base" },
  { label: "Base-General", value: "base-general" },
  { label: "Base-Meeting", value: "base-meeting" },
  { label: "Base-Phonecall", value: "base-phonecall" },
  { label: "Base-Finance", value: "base-finance" },
  { label: "Base-Conversationalai", value: "base-conversationalai" },
  { label: "Base-Voicemail", value: "base-voicemail" },
  { label: "Base-Video", value: "base-video" },
  { label: "Whisper-Tiny", value: "whisper-tiny" },
  { label: "Whisper-Base", value: "whisper-base" },
  { label: "Whisper-Small", value: "whisper-small" },
  { label: "Whisper-Medium OR Whisper", value: "whisper-medium OR whisper" },
  { label: "Whisper-Large", value: "whisper-large" },
];

export const deepgram_redact = [
  { label: "PII", value: "pii" },
  { label: "PHI", value: "phi" },
  { label: "PCI", value: "pci" },
  { label: "Account Number", value: "account_number" },
  { label: "Age", value: "age" },
  { label: "date", value: "date" },
  { label: "Date Interval", value: "date_interval" },
  { label: "Dob", value: "dob" },
  { label: "Driver License", value: "driver_license" },
  { label: "duration", value: "duration" },
  { label: "Email Address", value: "email_address" },
  { label: "Event", value: "event" },
  { label: "Filename", value: "filename" },
  { label: "Gender Sexuality", value: "gender_sexuality" },
  { label: "Healthcare Number", value: "healthcare_number" },
  { label: "Ip Address", value: "ip_address" },
  { label: "Language", value: "language" },
  { label: "Location", value: "location" },
  { label: "Location Address", value: "location_address" },
  { label: "Location City", value: "location_city" },
  { label: "Location Coordinate", value: "location_coordinate" },
  { label: "Location Country", value: "location_country" },
  { label: "Location State", value: "location_state" },
  { label: "Location Zip", value: "location_zip" },
  { label: "Marital Status", value: "marital_status" },
  { label: "Money", value: "money" },
  { label: "Name", value: "name" },
  { label: "Name Family", value: "name_family" },
  { label: "Name Given", value: "name_given" },
  { label: "Name Medical Professional", value: "name_medical_professional" },
  { label: "Numerical Pii", value: "numerical_pii" },
  { label: "Occupation", value: "occupation" },
  { label: "Organization", value: "organization" },
  {
    label: "Organization Medical Facility",
    value: "organization_medical_facility",
  },
  { label: "Origin", value: "origin" },
  { label: "Passport Number", value: "passport_number" },
  { label: "Password", value: "password" },
  { label: "Phone Number", value: "phone_number" },
  { label: "Physical Attribute", value: "physical_attribute" },
  { label: "Political Affiliation", value: "political_affiliation" },
  { label: "Ssn", value: "ssn" },
  { label: "Time", value: "time" },
  { label: "Url", value: "url" },
  { label: "Username", value: "username" },
  { label: "Vehicle Id", value: "vehicle_id" },
  { label: "Zodiac Sign", value: "zodiac_sign" },
  { label: "Blood Type", value: "blood_type" },
  { label: "Condition", value: "condition" },
  { label: "Dose", value: "dose" },
  { label: "Drug", value: "drug" },
  { label: "Injury", value: "injury" },
  { label: "Medical Process", value: "medical_process" },
  { label: "Credit Card", value: "credit_card" },
  { label: "Credit Card Expiration", value: "credit_card_expiration" },
  { label: "Cvv", value: "cvv" },
  { label: "Bank Account", value: "bank_account" },
  { label: "Routing Number", value: "routing_number" },
];

export const deepgram_language = [
  { label: "English", value: "en" },
  { label: "English-US", value: "en-US" },
  { label: "English-GB", value: "en-GB" },
  { label: "English-IN", value: "en-IN" },
  { label: "English-NZ", value: "en-NZ" },
  { label: "English-AU", value: "en-AU" },
  { label: "Bulgarian", value: "bg" },
  { label: "Catalan", value: "ca" },
  { label: "Czech", value: "cs" },
  { label: "Danish", value: "da" },
  { label: "Danish-da-DK", value: "da-DK" },
  { label: "Dutch", value: "nl" },
  { label: "Dutch-hi-Latn", value: "hi-Latn" },
  { label: "Estonian", value: "et" },
  { label: "Finnish", value: "fi" },
  { label: "Flemish", value: "nl" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "German (Switzerland)", value: "de" },
  { label: "Greek", value: "el" },
  { label: "Hindi", value: "hi" },
  { label: "Hungarian", value: "hu" },
  { label: "Indonesian", value: "id" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Korean", value: "ko" },
  { label: "Korean-KR", value: "ko-KR" },
  { label: "Latvian", value: "lv" },
  { label: "Lithuanian", value: "ms" },
  { label: "Malay", value: "en" },
  { label: "Norwegian", value: "no" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese", value: "pt" },
  { label: "Portuguese-BR", value: "pt-BR" },
  { label: "Romanian", value: "ro" },
  { label: "Russian", value: "ru" },
  { label: "Slovak", value: "sk" },
  { label: "b", value: "es" },
  { label: "b-es-419", value: "es-419" },
  { label: "Swedish", value: "sv" },
  { label: "Swedish-sv-SE", value: "sv-SE" },
  { label: "Thai", value: "no" },
  { label: "Turkish", value: "tr" },
  { label: "Ukrainian", value: "uk" },
  { label: "Vietnamese", value: "vi" },
];
