import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";
import { CircularProgress, Modal } from "@material-ui/core";
import SearchBar from "../../common/SearchBar";
import CaseManagerService from "../../../services/CaseManagerService";
import AddIcon from "@material-ui/icons/Add";
import AlertDialog from "../../common/Alert";
import useDebounce from "../../../hooks/useDebounce";
import queryString from "query-string";
import _ from "lodash";

export default function AssignCaseManager({
  state,
  setState,
  cmList,
  setCmList,
}) {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [inputText, setInputText] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedManger, setSelectedManager] = useState();
  const debounceValue = useDebounce(inputText, 500);

  useEffect(() => {
    if (state) {
      getCMList(debounceValue);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, debounceValue]);

  async function getCMList(query) {
    try {
      setLoading(true);
      let params = {
        page: 1,
        perPage: 1000,
        role: "caseManager",
      };
      if (query) {
        params.query = query;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      const response = await CaseManagerService.getCMlist(stringParams);
      if (response?.data) {
        setCmList(response?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function addCaseManager(selectedManger) {
    try {
      setLoader({ state: true, message: "Adding IIAC Counsel..." });

      const response = selectedManger;
      if (response) {
        setCmList(response);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
      setOpen(false);
    } finally {
      setLoader({ state: false });
      setState(false);
      setOpen(false);
    }
  }

  return (
    <Modal
      open={state}
      onClose={() => setState(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{labels.add_case_manager}</Heading>
          <CloseModal
            onClick={() => setState(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <div className="ml40 mr40">
          <div>
            <SearchBar
              name={"caseManger"}
              placeHolderText={labels["search_case_manager"]}
              backgroundColor="#f9f9f9"
              {...{ inputText, setInputText }}
              width="100%"
            />
          </div>
          <div
            className="mt20 mb20 h300"
            style={{
              overflow: "auto",
            }}
          >
            {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              <>
                {cmList?.length > 0 ? (
                  <>
                    {cmList?.map((cm) => (
                      <div
                        className={`cm_row ${cm?.disabled ? "disabled" : ""}`}
                      >
                        <div className="cm_name">
                          <div>{cm?.name}</div>
                          <div>({cm?.email})</div>
                        </div>
                        <div
                          className="add_circle"
                          onClick={() => {
                            if (!cm?.disabled) {
                              setOpen(true);
                              setSelectedManager(cm);
                            }
                          }}
                        >
                          <AddIcon className="add_icon" />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="no_result">No Casemangers found.</div>
                )}
              </>
            )}
          </div>
          <div>
            <AlertDialog
              isOpen={open}
              clickSecondarybtn={() => setOpen(false)}
              onDialogClose={() => setOpen(false)}
              primaryBtnText={"Okay"}
              secondaryBtnText={"Cancel"}
              desc={
                <StyledTextSemi>
                  Are you sure you want to add{" "}
                  <StyledText>{selectedManger?.name}</StyledText>, email ID{" "}
                  <StyledText>{selectedManger?.email}</StyledText> as the case
                  manager?
                </StyledTextSemi>
              }
              heading={labels.add_case_manager}
              clickPrimaryBtn={() => addCaseManager(selectedManger)}
              descriptionTextStyle={{
                textAlign: "left",
                fontFamily: theme.fonts.primaryFontSemiBold,
              }}
            />
          </div>
        </div>
      </DrawerContainer>
    </Modal>
  );
}

const DrawerContainer = styled.div`
  max-width: 516px;
  min-height: 318px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  & .no_result {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    justify-content: center;
    align-items: center;
    color: ${COLORS.COLOR_DARK};
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
  & .cm_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    }
    & .cm_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .add_circle {
      margin: 0px 20px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 131, 140, 0.13);
      cursor: pointer;
      & .add_icon {
        font-size: 14px;
        color: ${COLORS.BTN_GREEN};
      }
    }
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledTextSemi = styled.a`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const StyledText = styled.a`
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;
