import API from "../api/manager";
import config from "../api/config";

const getProfiles = (query) => {
  return API({
    method: "GET",
    url: config.urls.user.index + query,
  });
};

const getInvitedProfiles = (query) => {
  return API({
    method: "GET",
    url: config.urls.user.invitedMediator + query,
  });
};

const getProfile = (id) => {
  return API({
    method: "GET",
    url: config.urls.user.index + `/${id}`,
  });
};

const setProfile = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.user.update + `/${id}`,
    data,
  });
};

const setConfig = (data) => {
  return API({
    method: "POST",
    url: config.urls.systemConfig,
    data,
  });
};

const getConfig = () => {
  return API(
    {
      method: "GET",
      url: config.urls.systemConfig,
    },
    false
  );
};

export default {
  getConfig,
  setConfig,
  getProfiles,
  getProfile,
  setProfile,
  getInvitedProfiles,
};
