import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div``;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${(props) => (props?.isError ? COLORS.LOGOUT_RED : COLORS.BTN_GREEN)};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  width: 100%;
  @media (min-width: 780px) {
    width: 90px;
  }
`;
export const StyledAmount = styled.div`
  @media ${theme?.breakpoints?.sm_up} {
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;
export const Padding = styled.div`
  width: 140px;
`;

export const TopCenter = styled.div`
  margin-left: 40px;
  text-decoration: none;

  @media (min-width: 780px) {
    margin-left: 0px;
  }
`;

export const Center = styled.div`
  margin-left: 0;
  @media ${theme?.breakpoints?.sm_up} {
    margin-left: 35px;
  }
`;

export const DeleteButton = styled.div`
  text-align: center;
  @media (min-width: 780px) {
    margin-top: 10px;
    width: 80%;
  }
`;
