import React from "react";
import styled from "styled-components";
import FilterBar from "./FilterBar";
import SearchBar from "./SearchBar";
import useDebouncedSearch from "../../../../hooks/useDebounceSearch";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";

const TableToolbar = ({
  MTRef,
  selectedFilter,
  setSelectedFilter,
  filters,
  placeholderText,
  hideSearch,
  hideFilterBar,
  customFilter,
  filterCount,
  onFilterClick = () => {},
  ...props
}) => {
  const { inputText, setInputText } = useDebouncedSearch(searchInput);
  function searchInput(input) {
    MTRef.current.onSearchChange(input);
  }

  return (
    <StyledMTableToolbar {...props} hideFilterBar={hideFilterBar}>
      {!hideFilterBar ? (
        <FilterBar
          filters={filters}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ) : (
        <div />
      )}
      {customFilter && (
        <CustomFilterButton onClick={() => onFilterClick()}>
          <FilterIcon />
          <FilterText>Filters</FilterText>
          <FilterCount>{filterCount}</FilterCount>
        </CustomFilterButton>
      )}
      {!hideSearch && (
        <SearchBar
          placeHolderText={placeholderText}
          {...{ inputText, setInputText }}
        />
      )}
    </StyledMTableToolbar>
  );
};

export default TableToolbar;

const StyledMTableToolbar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${(hideFilterBar) =>
    !hideFilterBar ? "flex-end" : "space-between"};
  align-items: center;
  padding-top: 0px;
  padding-bottom: 17px;
`;
const CustomFilterButton = styled.div`
  display: flex;
  align-items: center;
  width: 116px;
  height: 27px;
  border-radius: 4px;
  border: solid 1px ${COLORS.BTN_GREEN};
  background-color: ${COLORS.PRIMARY_WHITE};
  justify-content: space-between;
  cursor: pointer;
  margin-left: 13px;
  user-select: none;
`;

const FilterIcon = styled.img`
  margin-left: 11px;
`;
const FilterText = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 12px;
`;
const FilterCount = styled.span`
  font-size: 16px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-right: 18px;
`;

// const Flex = styled.div`
//   display: flex;
//   align-items: center;
// `;
