import React from 'react';
import styled from 'styled-components';

const VideoPlayer = ({ mediaUrl,
    videoRef,
    onLoadedDataGetDuration,
    onTimeUpdate
}) => {

    const handlePlayMedia = () => {
        if (videoRef.current !== null) {
            return videoRef.current.paused
                ? videoRef.current.play()
                : videoRef.current.pause();
        }
    };

    return (
        <VideoEl>
            <video
                id="video"
                playsInline
                src={mediaUrl}
                controls
                onTimeUpdate={onTimeUpdate}
                data-testid="media-player-id"
                onClick={() => handlePlayMedia()}
                onLoadedData={onLoadedDataGetDuration}
                ref={videoRef}
                preload="auto"
                style={{
                    display: "inline"
                }}
            />
        </VideoEl>
    );
}

export default VideoPlayer;

const VideoEl = styled.video`
    width: 100%;
`
