import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import StyledAppBar from "./StyledAppBar";
import Sidebar from "./Sidebar";
import useUser from "../../../hooks/useUser";
import { useLocation } from "@reach/router";
import useCartCount from "../../../hooks/useCartCount";
import _ from "lodash";
import CaseManagerService from "../../../services/CaseManagerService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    marginTop: 74,
  },
}));

export function Header({
  children,
  showSidebar = false,
  selectedItem,
  selectedSubItem,
}) {
  const classes = useStyles();
  const { userObject } = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const {
    triggerCount,
    setTriggerCount,
    setCartCount,
    setStats,
    stats,
  } = useCartCount();

  useEffect(() => {
    if (!_.isEmpty(userObject) && triggerCount) {
      CaseManagerService.getCaseStats("").then(
        (res) => {
          setCartCount(res?.inCartCount);
          setTriggerCount(false);
          setStats(res);
        },
        (err) => {
          console.log(err);
          setTriggerCount(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject, triggerCount]);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <StyledAppBar />
      {showSidebar && (
        <Sidebar
          {...{ selectedItem, selectedSubItem, stats }}
          selectedSubItem={
            searchParams.get("caseType")
              ? searchParams.get("caseType")
              : selectedSubItem
          }
        />
      )}
      <main className={classes.content}>{children}</main>
    </div>
  );
}
