import React from "react";
import labels from "./helpers/labels.json";
import { Router } from "@reach/router";
import ManagerLogin from "./components/pages/ManagerLogin";
import LoginPath from "./components/pages/ManagerLogin/LoginPath";
import DashboardScreens from "./components/pages/DashboardScreens";
import CaseManager from "./components/pages/TemporatyRoute";
import ServerRoom from "./components/pages/TemporatyRoute";
import PaymentsPage from "./components/pages/PaymentsPage";
import PaymentHistoryPage from "./components/internal/PaymentHistoryPage/index";
import Cases from "./components/pages/TemporatyRoute";
import Mediator from "./components/pages/TemporatyRoute";
import Configurations from "./components/pages/Configurations";
import MyConfigurations from "./components/internal/MyConfigurations";
import UpdateTerminologies from "./components/internal/UpdateTerminologies";
import UpdateFAQs from "./components/pages/UpdateFAQs";
import UpdateTandC from "./components/pages/UpdateTandC";
import UpdatePrivacyPolicy from "./components/pages/UpdatePrivacyPolicy";
import UpdateArbitratorsInfo from "./components/pages/UpdateArbitratorsInfo";
import ProfileDetails from "./components/pages/ProfileDetails";
import CaseManagerTable from "./components/internal/CaseManagerTable";
import ServerTable from "./components/internal/ServerTable/ServerTable";
import CreateCaseManager from "./components/internal/CreateCaseManager";
import ViewCaseManager from "./components/internal/ViewCaseManager";
import EditCaseManager from "./components/internal/EditCaseManager";
import CaseListing from "./components/internal/CaseListing";
import CaseDetails from "./components/pages/CaseDetails";
import MediatorPage from "./components/internal/MediatorPage";
import Recordings from "./components/pages/Recordings/index";
import RecordingsPage from "./components/internal/RecordingsPage/index";
import GuestGenericMeeting from "./components/pages/JoinGenericMeeting";
import TimeSlideTranscription from "./components/pages/TimeSlideTranscription/index";
import RecordingsTimeSlide from "./components/pages/RecordingsTimeSlide";
import LiveTranscription from "./components/internal/TimeSlideTranscript/LiveTranscription";
import AudioTranscription from "./components/pages/TimeSlideTranscription/AudioTranscription";
import SessionManagement from "./components/internal/SessionManagement/index";
import CrossExamination from "./components/internal/CrossExamination/index";
import CrossExaminationTranscript from "./components/internal/CrossExamination/CrossExaminationTranscripts";
import TemporaryRoute from "./components/pages/TemporatyRoute";
// import DailyCause from "./components/internal/DailyCause";
import DailyCause from "./components/calender/DailyCause";

const AppRouter = () => {
  return (
    <Router>
      <LoginPath path="/">
        <ManagerLogin path="/" />
      </LoginPath>
      <DashboardScreens path="/dashboard">
        <CaseManager selectedItem={labels.case_manager} path="/">
          <CaseManagerTable path="/" />
          <CreateCaseManager path="/create-case-manager" />
          <EditCaseManager path="/edit/:id" />
          <ViewCaseManager path="/:id" />
        </CaseManager>
        <Cases selectedItem={labels.cases} path="/cases">
          <CaseListing path="/" />
          <CaseDetails path="/:id" />
          <TimeSlideTranscription path="/:id/transcription" />
          <AudioTranscription path="/:id/split-transcription" />
          <LiveTranscription path="/:id/livetranscription" />
          <CrossExamination path="/:id/cross-examination" />
          <CrossExaminationTranscript path="/:id/cross-examination-transcript" />
        </Cases>
        <Mediator selectedItem={labels.mediator} path="/arbitrator">
          {/* <MediatorListing path="/" /> */}
          <MediatorPage path="/" />
          <ProfileDetails path="/:userId" />
        </Mediator>
        <ServerRoom selectedItem={labels.server_room} path="/server-room">
          <ServerTable path="/" />
          <SessionManagement path="/session-management" />
        </ServerRoom>
        <PaymentsPage path="/payments">
          <PaymentHistoryPage path="/" />
        </PaymentsPage>
        <Configurations
          selectedItem={labels.configurations}
          path="/configurations"
        >
          <MyConfigurations path="/" />
          <UpdateTerminologies path="/terms" />
          <UpdateFAQs path="/faqs" />
          <UpdateTandC path="/terms-and-condition" />
          <UpdatePrivacyPolicy path="/privacy-policy" />
          <UpdateArbitratorsInfo path="arbitrators-info" />
        </Configurations>
        <Recordings selectedItem={labels.meeting_details} path="/recordings">
          <RecordingsPage path="/" />
          <RecordingsTimeSlide path="/transcription" />
        </Recordings>
        <TemporaryRoute selectedItem={labels.daily_casuse} path="/daily-cause">
          <DailyCause path="/" />
        </TemporaryRoute>
      </DashboardScreens>
      <GuestGenericMeeting path="/joinMeeting/:code" />
    </Router>
  );
};

export default AppRouter;
