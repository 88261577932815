import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";

const ProgresBar = ({
    getProgressBarMax,
    getProgressBarValue,
    handleProgressBarClick }) => {

    return (
        <Wrapper>
            <Bar
                style={{
                    accentColor: COLORS.BTN_GREEN,
                }}
                type='range'
                onChange={(e) => handleProgressBarClick(e)}
                value={getProgressBarValue}
                min='0'
                max={getProgressBarMax.toString()}
            />
        </Wrapper>
    )
}

export default ProgresBar;

const Wrapper = styled.div`
    width: 100%;
    overflow-x: hidden;
    position: absolute;
    left: 0;
    bottom: -20px;
`

const Bar = styled.input`
    width: 100%;
    height: 30px;
    margin: 0;
    cursor: pointer;
`