import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { sanitize } from "dompurify";

const useStyles = makeStyles((themes) => ({
  root1: {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "none",
  },
  root: {
    width: "100%",
    backgroundColor: "white",
    borderBottom: `0.2px solid #e4e5ef !important`,
    boxShadow: "none",
  },
  heading: {
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: "18px",
    color: COLORS.BTN_GREEN,
    paddingLeft: "unset",
  },
  secondaryHeading: {
    width: "960px",
    fontFamily: theme.fonts.primaryFontRegular,
    fontSize: "14px",
    color: COLORS.COLOR_DARK,
    lineHeight: "1.57",
  },
}));

const Index = ({ data = [] }) => {
  const classes = useStyles();
  const [states, setStates] = useState([]);
  useEffect(() => {
    if (data?.length) {
      const truearr = new Array(data.length).fill(true);
      setStates(truearr);
    }
  }, [data]);
  return (
    <Bg>
      {states.length ? (
        data.map((card, index) => (
          <Accordion
            expanded={states[index]}
            className={data.length - 1 === index ? classes.root1 : classes.root}
            key={index}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  onClick={() => {
                    let newState = [...states];
                    newState[index] = !states[index];
                    setStates(newState);
                  }}
                />
              }
            >
              <div className={classes.heading}>{card.question}</div>
            </AccordionSummary>
            <AccordionDetails>
              <div
                className={`${classes.secondaryHeading} ql-editor`}
                dangerouslySetInnerHTML={{ __html: sanitize(card.answer) }}
              ></div>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <EmptyState>No Configuration Found</EmptyState>
      )}
    </Bg>
  );
};

export default Index;

const Bg = styled.div`
  background-color: white;
  padding: 12px 22px;
  border-radius: 10px;
  border: solid 0.5px #e4e5ef;
`;

const EmptyState = styled.div`
  color: ${COLORS.PRIMARY_BLACK};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
`;
