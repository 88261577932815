import React, { useState } from "react";
import { Center } from "../../../styles/component/style";
import { Header } from "../../internal/Header/Header";
import Login from "../../internal/Login/Login";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";

const Index = ({ location = {} }) => {
  const [inputs] = useState([
    {
      name: "email",
      label: "REGISTERED EMAIL",
      type: "email",
      placeholder: `Enter Your Email`,
    },
    {
      name: "password",
      label: "PASSWORD",
      type: "password",
      placeholder: `Enter your Password`,
    },
  ]);

  return (
    <Header>
      <Center>
        <SignupContainer>
          <Login heading={"ADMIN LOGIN"} inputs={inputs} {...{ location }} />
        </SignupContainer>
      </Center>
    </Header>
  );
};

export default Index;

export const SignupContainer = styled.div`
  background: ${COLORS.PRIMARY_WHITE};
  border-radius: 11px;
  width: 516px;
  margin: 91px 0px 20px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
    padding: 30px 30px;
  }
`;
