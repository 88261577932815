import React, { useState } from "react";

export const LoaderContext = React.createContext();

function LoaderProvider({ children }) {
  const [loader, setLoader] = useState({
    state: false,
  });

  const value = React.useMemo(() => {
    return {
      loader,
      setLoader,
    };
  }, [loader, setLoader]);

  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
}

export default LoaderProvider;
