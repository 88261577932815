import React from "react";
import { Modal } from "@material-ui/core";
import useCalendar from "../../../hooks/useCalender";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

const CauseListModal = () => {
  const { showEventModal, setShowEventModal } = useCalendar();

  function convertTime(time24) {
    const [hours, minutes] = time24.split(":");

    // Creating a new Date object
    let time12 = new Date(2024, 1, 1, hours, minutes);

    // Formatting the time into 12-hour format
    time12 = time12.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return time12;
  }

  return (
    <Modal
      open={showEventModal}
      disableRestoreFocus={true}
      onClose={() => setShowEventModal({ state: false })}
    >
      <ModalContainer>
        <Container>
          <HeadingContainer>
            <Heading>Daily Cause List</Heading>
            <CloseModal
              src={require("../../../assets/images/closeModal.svg")}
              onClick={() => setShowEventModal({ state: false })}
            />
          </HeadingContainer>
          <CauseContainer>
            <LabelContainer>
              <LabelValueContainer>
                <Label>Title</Label>
                <Value>{showEventModal?.data?.title}</Value>
              </LabelValueContainer>
            </LabelContainer>
            <BasicDetails>
              <LabelContainer>
                <LabelValueContainer>
                  <Label>Case Id</Label>
                  <Value>{showEventModal?.data?.caseId}</Value>
                </LabelValueContainer>
              </LabelContainer>
              <LabelContainer>
                <LabelValueContainer>
                  <Label>{"Date"}</Label>
                  <Value>{showEventModal?.data?.date}</Value>
                </LabelValueContainer>
              </LabelContainer>
              <LabelContainer>
                <LabelValueContainer>
                  <Label>{"Time"}</Label>
                  <Value>{convertTime(showEventModal?.data?.time)}</Value>
                </LabelValueContainer>
              </LabelContainer>
            </BasicDetails>
            <LabelContainer>
              <LabelValueContainer>
                <Label>Description</Label>
                <Value>{showEventModal?.data?.description}</Value>
              </LabelValueContainer>
            </LabelContainer>
          </CauseContainer>
        </Container>
      </ModalContainer>
    </Modal>
  );
};

export default CauseListModal;

const ModalContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 35%;
  }
`;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const CauseContainer = styled.div`
  width: 100%;
  padding-top: 14px;
  padding-left: 38px;
  padding-bottom: 26px;
  line-height: 1.71;
  max-height: 385px;
  overflow: auto;
`;

const LabelContainer = styled.div`
  margin-right: 25px;
  margin-bottom: 20px;
  @media (min-width: 640px) {
    margin-right: 0;
  }
`;

const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.9;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;

const Value = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
  word-break: break-all;
  padding-right: 6px;
`;

const BasicDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
`;
