import React from "react";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import { FormLabel, Modal } from "@material-ui/core";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../../common/Buttons";
import { CustomInputField } from "../../../common/FormInputs";
import CopyToClipboard from "react-copy-to-clipboard";

export default function RTMP({
  streamModal,
  setStreamModal,
  streamData,
  id,
  handleStart = () => {},
}) {
  const rtmp_action = [
    {
      label: "Streaming Url",
      value: streamData?.rtmp_url,
    },
    {
      label: "Streaming Key",
      value: streamData?.streaming_key,
    },
  ];

  return (
    <Modal open={streamModal} onClose={() => setStreamModal(false)}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>RTMP Server</Heading>
          <CloseModal
            onClick={() => setStreamModal(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          {streamData?.rtmp_url
            ? rtmp_action.map((rtmp) => (
                <>
                  <FormLabel
                    style={{
                      marginLeft: "10px",
                      fontFamily: theme.fonts.primaryFontSemiBold,
                      fontSize: 12,
                      color: COLORS.INPUT_LABEL,
                    }}
                  >
                    {rtmp.label}
                  </FormLabel>
                  <div className="rtmp">
                    <CustomInputField
                      style={{ padding: "10px 10px" }}
                      type={"text"}
                      value={rtmp?.value}
                      disabled={true}
                      placeholder="Type Something to Copy"
                      variant="outlined"
                    />
                    <CopyToClipboard text={rtmp?.value} onCopy={true}>
                      <PrimaryCTAButton
                        style={{
                          width: "50%",
                          height: "38px",
                          marginTop: "10px",
                        }}
                      >
                        Click to Copy
                      </PrimaryCTAButton>
                    </CopyToClipboard>
                  </div>
                </>
              ))
            : null}
          <TextWrap>
            <StyledTextSemi>
              * Please copy Streaming URL and Key and paste it on Video
              Conferencing platform and then click{" "}
              <StyledText>Confirm</StyledText>
            </StyledTextSemi>
          </TextWrap>
          <ButtonContainer
            style={{
              width: "418px",
              justifyContent: "center",
            }}
          >
            <PrimaryOutlinedCTAButton
              style={{ width: "38%", padding: "8px 8px", marginRight: "15px" }}
              onClick={(e) => {
                setStreamModal(false);
              }}
            >
              Close
            </PrimaryOutlinedCTAButton>
            <PrimaryCTAButton
              style={{ width: "38%", padding: "8px 8px" }}
              onClick={() => handleStart(id)}
            >
              Confirm
            </PrimaryCTAButton>
          </ButtonContainer>
        </AgentFormContainer>
      </DrawerContainer>
    </Modal>
  );
}

const DrawerContainer = styled.div`
  max-width: 516px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
`;

const StyledTextSemi = styled.a`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const StyledText = styled.a`
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const TextWrap = styled.div`
  text-align: left;
  margin-left: 10px;
  margin-top: 10px;
  font-style: italic;
`;
