import React, { useState, useRef } from "react";
import { useLocation, navigate } from "@reach/router";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import theme from "../../../assets/theme";
import queryString from "query-string";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import CustomTable from "../../common/CustomTable/CustomTable";
import SessionMetaModal from "./MetaModal";
import COLORS from "../../../assets/Colors";
import Invoice from "./Invoice";

const Index = () => {
  const history = useLocation();
  const previousPath = history?.pathname.replace("/session-management", "");
  const breadcrumbs = ["Server Room", "Session Management"];
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const MTRef = useRef();
  const [result, setResult] = useState({});
  const meetingId = history?.state?.meetingId;
  const [state, setState] = useState({ state: false });
  const [modal, setModal] = useState({ state: false });

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "server_room":
        navigate(`${previousPath}`);
        break;
      default:
        break;
    }
  };
  async function getCaseMeetingDetails(id, query) {
    try {
      setLoader({ state: true, message: `Loading recordings...` });
      const response = await CaseService.getCaseMeetingDetails(id, query);
      return { ...response };
    } catch (error) {
      console.log(error);
    } finally {
      setLoader({ state: false });
    }
  }

  const columns = [
    {
      field: "internalMeetingId",
      title: "Internal Meeting Id",
      sorting: false,
      render: (rowData) =>
        rowData?.internalMeetingId ? (
          <Bold
            style={{ cursor: "pointer" }}
            onClick={() => window.open(rowData?.RecordingUrl)}
          >
            {rowData?.internalMeetingId}
          </Bold>
        ) : (
          <Bold style={{ marginLeft: "15px" }}>{"-"}</Bold>
        ),
      headerStyle: {
        whiteSpace: "none",
      },
    },
    {
      field: "dateOfHearing",
      title: "Date Of Hearing",
      sorting: false,
      render: (rowData) => <Bold>{rowData?.dateOfHearing}</Bold>,
      cellStyle: {
        paddingLeft: 28,
      },
    },
    {
      field: "sessionDuration",
      title: "Session Duration",
      sorting: false,
      render: (rowData) =>
        rowData?.sessionDuration ? (
          <Bold>{rowData?.sessionDuration}</Bold>
        ) : (
          <Bold>{"-"}</Bold>
        ),
      cellStyle: {
        paddingLeft: 52,
      },
    },
    {
      field: "recordingDuration",
      title: "Recording Duration",
      sorting: false,
      render: (rowData) =>
        rowData?.recordingDuration ? (
          <Bold>{rowData?.recordingDuration}</Bold>
        ) : (
          <Bold style={{ marginLeft: "25px" }}>{"-"}</Bold>
        ),
      cellStyle: {
        paddingLeft: 32,
      },
    },
    {
      field: "transcriptionDuration",
      title: "Transcription Duration",
      sorting: false,
      render: (rowData) =>
        rowData?.transcriptionDuration ? (
          <Bold>{rowData?.transcriptionDuration}</Bold>
        ) : (
          <Bold style={{ marginLeft: "35px" }}>{"-"}</Bold>
        ),
      cellStyle: {
        paddingLeft: 32,
      },
    },
    {
      field: "",
      title: "View",
      sorting: false,
      render: (rowData) => (
        <HyperLink onClick={() => setState({ state: true, meta: rowData })}>
          {"View"}
        </HyperLink>
      ),
    },
    {
      field: "",
      title: "Invoice",
      sorting: false,
      render: (rowData) => (
        <ButtonWrapper>
          <InvoiceButton
            onClick={() => setModal({ state: true, data: rowData })}
          >
            <img
              src={require("../../../assets/images/Payment History_active_Blue.svg")}
              alt="accept"
            />
          </InvoiceButton>
        </ButtonWrapper>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let payload = {
        page: query.page + 1,
        perPage: 10,
      };
      const stringParams = `?${queryString.stringify(payload)}`;
      getCaseMeetingDetails(meetingId, stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setResult(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setResult({
            data: [],
            lastPage: 1,
            page: 1,
            perPage: 10,
            total: 0,
          });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <React.Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container>
        <TableWrapper>
          <CustomTable
            hidePagination={result.lastPage === 1}
            pageSize={10}
            pluralTitle="Meeting Details"
            singularTitle=""
            {...{
              columns,
              data,
              MTRef,
            }}
            noToolbar
            // hideFilterBar
            state={result}
          />
        </TableWrapper>
      </Container>
      <SessionMetaModal {...{ state, setState, MTRef }} />
      <Invoice {...{ modal, setModal }} />
    </React.Fragment>
  );
};

export default Index;

const Bold = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div``;

const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
  width: 65%;
  @media (min-width) {
    justify-content: space-between;
  }
`;

const InvoiceButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
