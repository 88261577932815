import React, { Fragment, useEffect, useState } from "react";
import { Modal, FormLabel, CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import { InvoiceValidationSchema } from "../../../helpers/validationSchema";
import { CustomRadioGroup } from "../../common/FormInputs";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../common/Buttons";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import moment from "moment";

const Invoice = ({ modal, setModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [loading, setLoading] = useState(false);

  const [agentInputs, setAgentInputs] = useState([
    {
      name: "claimantPercentage",
      type: "number",
      label: "Claimant percentage",
      required: true,
    },
  ]);

  const [claimantInputs, setClaimantInputs] = useState([]);
  const [data, setDate] = useState();

  useEffect(() => {
    async function getCaseDetails(caseId) {
      try {
        setLoading(true);
        const response = await CaseService.getCaseDetailsForInvoice(caseId);
        setDate(response);
        setClaimantInputs(response.claimantParties);
        setAgentInputs((prevArray) => [
          ...prevArray,
          ...response.respondentParties.map((item, index) => {
            return {
              name: `r${index + 1}Percentage`,
              type: "number",
              label: `Respondent${index + 1} percentage`,
              partyName: item?.partyName,
            };
          }),
        ]);
      } catch (error) {
        setLoading(false);
        throw error;
      } finally {
        setLoading(false);
      }
    }
    if (modal?.state) {
      getCaseDetails(modal?.data?.caseId);
    } else {
      setAgentInputs([
        {
          name: "claimantPercentage",
          type: "number",
          label: "Claimant percentage",
          required: true,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.state]);

  /* Invoice inputs*/

  const inputs = [
    {
      name: "transcriptionLotRate",
      type: "number",
      label: "Transcription Rate",
      required: true,
    },
    {
      name: "caseManagementLotRate",
      type: "number",
      label: "Case Management Rate",
      required: true,
    },
    {
      type: "text",
      name: "invoiceDescription",
      label: "Invoice Description",
      required: true,
    },
    {
      type: "email",
      name: "mailId",
      label: "MailId ( Invoice to be sent)",
      required: true,
    },
    {
      type: "time",
      name: "sessionStartTime",
      label: "SessionStart Time",
      required: true,
      placeholder: "Start Time",
    },
    {
      type: "time",
      name: "sessionEndTime",
      label: "sessionEnd Time",
      required: true,
      placeholder: "End Time",
    },
  ];

  /* Claimant inputs*/

  /* respondent Inputs */

  const claimant_respondent_inputs = [
    {
      name: "partyName",
      type: "text",
      label: "Party Name",
    },
    {
      name: "address",
      type: "text",
      label: "Address",
    },
    {
      name: "gstNumber",
      type: "text",
      label: "GST Number",
    },
    {
      name: "documentNumber",
      type: "text",
      label:
        claimantInputs[0]?.documentKind === "pan"
          ? "Pan"
          : claimantInputs[0]?.documentKind === "aadhar"
          ? "Aadhar"
          : claimantInputs[0]?.documentKind === "voter"
          ? "Voter"
          : claimantInputs[0]?.documentKind === "passport"
          ? "Passport"
          : "Cin",
    },
    {
      name: "cinNumber",
      type: "text",
      label: "Cin Number",
    },
    {
      type: "text",
      name: "invoiceNumber",
      label: "Invoice Number",
      required: true,
    },
  ];

  function setInitialValues(data) {
    if (data?.respondentParties) {
      return data?.respondentParties?.map((el) => {
        return {
          pin: el?.pin,
          city: el?.city,
          email: el?.email,
          state: el?.state,
          partyId: el?.partyId,
          locality: el?.locality,
          cinNumber: el?.cinNumber,
          gstNumber: el?.gstNumber,
          partyName: el?.partyName,
          partyRole: el?.partyRole,
          houseNumber: el?.houseNumber,
          documentKind: el?.documentKind,
          documentNumber: el?.documentNumber,
          invoiceNumber: el?.invoiceNumber,
          address: el?.address,
          selected: "",
        };
      });
    }
  }

  function setClaimantInitialValues(data) {
    if (data?.claimantParties) {
      return data?.claimantParties?.map((el) => {
        return {
          pin: el?.pin,
          city: el?.city,
          email: el?.email,
          state: el?.state,
          partyId: el?.partyId,
          locality: el?.locality,
          cinNumber: el?.cinNumber,
          gstNumber: el?.gstNumber,
          partyName: el?.partyName,
          partyRole: el?.partyRole,
          houseNumber: el?.houseNumber,
          documentKind: el?.documentKind,
          documentNumber: el?.documentNumber,
          invoiceNumber: el?.invoiceNumber,
          address: el?.address,
        };
      });
    }
  }

  const onFormSubmit = async (values) => {
    try {
      setLoader({ state: true, message: "Generate Invoice..." });
      let percentage = [];
      for (let key in values) {
        if (key.includes("Percentage") && key !== "claimantPercentage") {
          percentage.push(parseInt(values[key]));
        }
      }
      const payload = {
        ...values,
        sessionStartTime: moment(new Date(values?.sessionStartTime)).format(
          "HH:mm:ss"
        ),
        sessionEndTime: moment(new Date(values.sessionEndTime)).format(
          "HH:mm:ss"
        ),
        caseId: modal?.data?.caseId,
        meetingId: modal?.data?.meetingId,
        internalMeetingId: modal?.data?.internalMeetingId,
        recordingDuration: modal?.data?.recordingDuration,
        sessionDuration: modal?.data?.sessionDuration,
        transcriptionDurationInSeconds:
          modal?.data?.transcriptionDurationInSeconds,
        sessionDurationInSeconds: modal?.data?.sessionDurationInSeconds,
        recordingDurationInMins: modal?.data?.recordingDurationInMins,
        transcriptionDuration: modal?.data?.transcriptionDuration,
        dateOfHearing: modal?.data?.dateOfHearing,
        resolutionKind: data?.resolutionKind,
        billingLotInMins: values?.billingLot === "1hr" ? 60 : 180,
        respondentPercentage: percentage.filter((acc) => acc),
        respondentParties: values?.respondentParties.filter(
          (party) => party?.selected
        ),
        claimantParties: values?.claimantPercentage
          ? values.claimantParties
          : [],
      };
      const response = await CaseService.generateInvoice(payload);
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
    }
  };

  const formikValidation = (values) => {
    let errors = {};
    if (values?.claimantPercentage && values.claimantParties) {
      values.claimantParties.forEach((item, index) => {
        if (!item.invoiceNumber) {
          errors.claimantParties = [
            {
              invoiceNumber: "Invoice number is required",
            },
          ];
        }
      });
    }

    const storedErrors = [];

    const filterArray = agentInputs
      .filter((item) => item?.name !== "claimantPercentage")
      .map((arr) => arr?.name);

    if (filterArray.length) {
      filterArray.map((arr, arrIndex) => {
        if (values[arr] && values.respondentParties) {
          const selectedItem = values.respondentParties.find(
            (item) =>
              item.partyId ===
              parseInt(values.respondentParties[arrIndex]?.partyId)
          );
          values.respondentParties.filter((inneerArray, innerIndex) => {
            if (
              !inneerArray.invoiceNumber &&
              inneerArray?.partyId === selectedItem?.partyId
            ) {
              return storedErrors.push({
                index: arrIndex,
                error: true,
                partyId: inneerArray?.partyId,
              });
            }
            return inneerArray;
          });
        }
        return arr;
      });

      if (storedErrors?.length) {
        storedErrors.map((item) => {
          errors.respondentParties = values.respondentParties.map(
            (outerArray) => {
              return {
                ...(outerArray?.invoiceNumber
                  ? {}
                  : { invoiceNumber: "Invoice number is required" }),
              };
            }
          );
          return item;
        });
      }
    }

    return errors;
  };

  return (
    <Modal open={modal?.state} onClose={() => setModal({ state: false })}>
      <DrawerContainer loading={loading}>
        <Container>
          <HeadingContainer>
            <Heading>Generate Invoice</Heading>
            <CloseModal
              alt="submit"
              src={require("../../../assets/images/closeModal.svg")}
              onClick={() => setModal({ state: false })}
            />
          </HeadingContainer>

          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            <Formik
              initialValues={{
                invoiceDescription: "",
                transcriptionLotRate: "",
                caseManagementLotRate: "",
                sessionStartTime: null,
                sessionEndTime: null,
                billingLot: "",
                mailId: "",
                claimantPercentage: "",
                claimantParties: setClaimantInitialValues(data),
                respondentParties: setInitialValues(data),
                respondentPercentage: "",
              }}
              onSubmit={onFormSubmit}
              validationSchema={InvoiceValidationSchema}
              validate={formikValidation}
              validateOnBlur
              enableReinitialize
              validateOnChange
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                handleBlur,
                setFieldValue,
                isValid,
              }) => (
                <StyledForm>
                  <FormContainer>
                    <MainParent style={{ padding: "20px 0px 20px 0px" }}>
                      <ContainerOne className="invoice">
                        <HeadingsLabel>Invoice</HeadingsLabel>
                        <ContainerOneGrid className="disabledArrows">
                          {inputs.map((input, index) => {
                            /* Invoice View */
                            return (
                              <FormField
                                key={index}
                                menuItems={input.menuItems}
                                {...{
                                  touched,
                                  errors,
                                  input,
                                  values,
                                  handleChange,
                                  handleBlur,
                                  setFieldValue,
                                }}
                              />
                            );
                          })}
                          <div
                            style={{ paddingTop: "22px", paddingLeft: "5px" }}
                          >
                            <div style={{ display: "flex" }}>
                              <FormLabel
                                style={{
                                  fontFamily: theme.fonts.primaryFontSemiBold,
                                  fontSize: 12,
                                  color: COLORS.INPUT_LABEL,
                                }}
                              >
                                Billing Lot{" "}
                                <span className="required-star">*</span>
                              </FormLabel>
                              {touched?.billingLot && errors?.billingLot && (
                                <HelperText>{errors?.billingLot}</HelperText>
                              )}
                            </div>
                            <ContainerRadio>
                              <CustomRadioGroup
                                radioInputs={[
                                  {
                                    label: "1 Hour",
                                    value: "1hr",
                                  },
                                  {
                                    label: "3 Hour",
                                    value: "3hr",
                                  },
                                ]}
                                value={values.billingLot}
                                handleChange={(e) =>
                                  handleChange(e, "billingLot")
                                }
                                name={`billingLot`}
                                {...{ values, handleChange }}
                              />
                            </ContainerRadio>
                          </div>
                        </ContainerOneGrid>
                      </ContainerOne>
                    </MainParent>
                    <MainParent style={{ padding: "20px 0px 20px 0px" }}>
                      <ContainerOne className="invoice">
                        <HeadingsLabel>
                          Claimant and Respondent Percentange
                        </HeadingsLabel>
                        <ContainerOneGrid className="disabledArrows">
                          {agentInputs &&
                            agentInputs.map((input, index) => {
                              return (
                                <FormField
                                  key={index}
                                  {...{
                                    touched,
                                    errors,
                                    input,
                                    values,
                                    // handleChange,
                                    handleBlur,
                                    setFieldValue,
                                  }}
                                  handleChange={(event) => {
                                    setFieldValue(
                                      input.name,
                                      event?.target?.value
                                    );
                                    setFieldValue(
                                      "respondentParties",
                                      values?.respondentParties &&
                                        values?.respondentParties.map(
                                          (arr, index) => ({
                                            ...arr,
                                            selected:
                                              input.partyName === arr.partyName
                                                ? event?.target?.value
                                                : arr.selected,
                                          })
                                        )
                                    );
                                  }}
                                />
                              );
                            })}
                        </ContainerOneGrid>
                      </ContainerOne>
                    </MainParent>
                    {values?.claimantPercentage > 0 /* Claimant view */ && (
                      <MainParent style={{ padding: "20px 0px 20px 0px" }}>
                        <ContainerOne className="invoice">
                          <HeadingsLabel>
                            Claimant Details (
                            {values?.claimantParties[0]?.partyName})
                          </HeadingsLabel>
                          <ContainerOneGrid className="disabledArrows">
                            {values?.claimantParties &&
                              values?.claimantParties.map((party, index) =>
                                claimant_respondent_inputs.map((input, key) => (
                                  <FormField
                                    white
                                    errors={
                                      errors && errors?.claimantParties
                                        ? errors?.claimantParties[index]
                                        : {}
                                    }
                                    touched={
                                      touched && touched?.claimantParties
                                        ? touched?.claimantParties[index]
                                        : {}
                                    }
                                    values={party}
                                    {...{
                                      input,
                                      handleBlur,
                                    }}
                                    handleChange={(e) => {
                                      if (input.name === "mobileNumber") {
                                        if (
                                          e?.target?.value?.match(/^[0-9]*$/)
                                        ) {
                                          setFieldValue(
                                            `claimantParties[${index}].${input?.name}`,
                                            e?.target?.value
                                          );
                                        }
                                      } else
                                        setFieldValue(
                                          `claimantParties[${index}].${input?.name}`,
                                          e?.target?.value
                                        );
                                    }}
                                  />
                                ))
                              )}
                          </ContainerOneGrid>
                        </ContainerOne>
                      </MainParent>
                    )}
                    {values?.claimantPercentage !== 100 /* Respondent view */ &&
                      agentInputs
                        .filter(
                          (item) =>
                            item?.name !== "claimantPercentage" && item.name
                        )
                        .map(
                          (item, index) =>
                            values[item?.name] > 0 && (
                              <MainParent
                                style={{ padding: "20px 0px 20px 0px" }}
                              >
                                <ContainerOne className="invoice">
                                  <HeadingsLabel>
                                    Respondent Details ({item?.partyName})
                                  </HeadingsLabel>
                                  <ContainerOneGrid className="disabledArrows">
                                    {values?.respondentParties &&
                                      values?.respondentParties.map(
                                        (party, index) =>
                                          item?.partyName ===
                                            party?.partyName && (
                                            <Fragment key={index}>
                                              {claimant_respondent_inputs.map(
                                                (input, key) => (
                                                  <FormField
                                                    key={key}
                                                    white
                                                    errors={
                                                      errors &&
                                                      errors?.respondentParties
                                                        ? errors
                                                            ?.respondentParties[
                                                            index
                                                          ]
                                                        : {}
                                                    }
                                                    touched={
                                                      touched &&
                                                      touched?.respondentParties
                                                        ? touched
                                                            ?.respondentParties[
                                                            index
                                                          ]
                                                        : {}
                                                    }
                                                    values={party}
                                                    {...{
                                                      input,
                                                      handleBlur,
                                                    }}
                                                    handleChange={(e) => {
                                                      if (
                                                        input.name ===
                                                        "mobileNumber"
                                                      ) {
                                                        if (
                                                          e?.target?.value?.match(
                                                            /^[0-9]*$/
                                                          )
                                                        ) {
                                                          setFieldValue(
                                                            `respondentParties[${index}].${input?.name}`,
                                                            e?.target?.value
                                                          );
                                                        }
                                                      } else
                                                        setFieldValue(
                                                          `respondentParties[${index}].${input?.name}`,
                                                          e?.target?.value
                                                        );
                                                    }}
                                                  />
                                                )
                                              )}
                                            </Fragment>
                                          )
                                      )}
                                  </ContainerOneGrid>
                                </ContainerOne>
                              </MainParent>
                            )
                        )}
                  </FormContainer>
                  <ButtonContainerNth>
                    <PrimaryOutlinedCTAButton
                      style={{
                        width: "18%",
                        marginRight: "35px",
                        padding: "10px 10px",
                      }}
                      onClick={() => setModal({ state: false })}
                    >
                      {"Cancel"}
                    </PrimaryOutlinedCTAButton>
                    <PrimaryCTAButton
                      style={{ width: "18%", padding: "10px 10px" }}
                      onClick={handleSubmit}
                    >
                      {"Submit"}
                    </PrimaryCTAButton>
                  </ButtonContainerNth>
                </StyledForm>
              )}
            </Formik>
          )}
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default Invoice;

const StyledForm = styled.form``;

const MainParent = styled.div``;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  @media ${theme?.breakpoints?.sm_up} {
    max-width: ${({ loading }) => (loading ? "35%" : "70%")};
  }
`;

const FormContainer = styled.div`
  width: 100%;
  //   padding: 24px 15px 30px 15px;
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  line-height: 1.71;
  backface-visibility: hidden;
  max-height: 475px;
  overflow: auto;
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const ContainerOne = styled.div`
  width: 100%;
  border-radius: 5px;
  border: solid 0.5px #e1e3ee;
  padding-top: 14px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 10px;
`;

const HeadingsLabel = styled(FormLabel)(() => ({
  fontFamily: theme.fonts.primaryFontSemiBold,
  color: "#808080",
  fontSize: "14px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ContainerOneGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
`;

const HelperText = styled.div`
  margin-left: 13px;
  bottom: 2px;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

const ContainerRadio = styled.div`
  display: flex;
`;

const ButtonContainerNth = styled.div`
  display: flex;
  margin-top: 12px;
  padding-bottom: 15px;
  justify-content: center;
`;
